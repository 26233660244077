<div class="d-flex justify-content-center flex-column sig-container p-2">
    <div class="sig-wrapper bordered-gray">
        <canvas id="signature-pad" class="signature-pad" width="500" height="300" (mouseup)="drawComplete($event)" (mousedown)="drawStart($event)"></canvas>
        <img *ngIf="source" [attr.src]="source" height="300" width="500" class="preview">
        <!-- <signature-pad #signature [options]="signaturePadOptions" (drawStart)="drawStart($event)" (drawEnd)="drawComplete($event)"></signature-pad> -->
    </div>
    <div class="pt-2 d-flex justify-content-between">
        <button class="btn btn-primary mr-2" (click)="reDraw()" *ngIf="source">Draw Again</button>
        <button class="btn btn-outline-primary mr-2" (click)="clear()" *ngIf="!source">Clear Signature</button>
        <button class="btn btn-primary mr-2" (click)="uploader.click()">Upload</button>
        <input type='file' accept="image/*" (change)="previewUploadFile($event)"  #uploader hidden>
    </div>
</div>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ScheduleService } from 'src/app/utils/services/schedule/schedule.service';
import { DoctorService } from 'src/app/utils/services/doctor/doctor.service';
import { VIRTAUL_CLINIC } from 'src/app/utils/items/clinic-type';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AppService } from 'src/app/utils/services/app.service';
import * as moment from 'moment';
import { VisitService } from 'src/app/utils/services/mpi/visit.service';

@Component({
  selector: 'app-edit-visit-history-modal',
  templateUrl: './edit-visit-history-modal.component.html',
  styleUrls: ['./edit-visit-history-modal.component.scss'],
})
export class VisitHistoryEditModalComponent {
  @Output() updated: EventEmitter<any> = new EventEmitter<any>();

  clinics: Array<any>;
  user: any;
  modal = false;
  date: any;
  start_date: any;
  end_date: any;
  schedule: any;
  complaint: string;
  service: any;
  service_item_code: any;
  appointment: any;
  visit: any;
  slot: any;
  clinic: any;
  searching = false;
  processing = false;
  loadingForm: boolean = true;

  constructor(
    private _serviceClient: ScheduleService,
    private _appService: AppService,
    private doctorService: DoctorService,
    private visitService: VisitService,
    private toastr: ToastrService
  ) {
    this.user = this._appService.getCurrentUser();
  }

  init(): void {
    this.modal = true;
    this.loadingForm = true;
    this.processing = false;
    this.clinics = null;
    this.slot = null;
  }

  onOpen(visit: any) {
    this.init();
    this.visit = visit;
    this.start_date = visit?.admission_datetime;
    this.end_date = visit?.discharge_datetime;
    this.complaint = visit?.chief_complaint;

    of(visit?.appointment)
      .pipe(
        filter((appt) => !!appt),
        switchMap((appt) => {
          this.appointment = appt;
          this.date = moment().format('YYYY-MM-DD');
          return this.getClinics().pipe(map((res) => res.data));
        }),
        switchMap((clinics) => {
          this.clinics = clinics;
          this.clinic = this.clinics?.find(
            (c) => c.resource.resource_id == this.visit.clinic_id
          );
          this.service = this.clinic.resource.services.find(
            (s) =>
              s.service_item_code === this.appointment.service.service_item_code
          );
          return this.getServiceSchedule();
        })
      )
      .subscribe(
        (schedule) => {
          this.schedule = schedule;
          this.loadingForm = false;

          if (schedule.slots?.length > 0) {
            this.slot = schedule.slots[0];
          }
        },
        (err) => {
          this.loadingForm = false;
        }
      );
  }

  clinicChange() {
    this.service = this.clinic.resource.services.find(
      (s) => s.service_item_code === this.appointment.service.service_item_code
    );
    this.listSchedules();
  }

  getClinics(): Observable<any> {
    return this.doctorService.getClinics({
      md_id: this.user?.doctor?.md_id,
      includes: 'resource.services.service_item',
      except_clinic_type: [VIRTAUL_CLINIC?.code],
    });
  }

  getServiceSchedule(): Observable<any> {
    return this._serviceClient.getServiceSchedules(
      this.service?.service_item_code,
      {
        date: this.date,
        resource_id: this.clinic?.resource?.id,
        clinic_id: this.clinic?.id,
      }
    );
  }

  listSchedules() {
    this.searching = true;
    this.slot = null;

    /* if (moment(this.date).isBefore(moment(), 'day')) {
      alert('Please select future date')
    } */

    this.getServiceSchedule().subscribe(
      (response: any) => {
        this.searching = false;
        this.slot = response?.slots?.[0];
        this.schedule = response;
        this.searching = false;
      },
      (err) => {
        this.searching = false;
      }
    );
  }

  updateVisit() {
    this.processing = true;

    this.visitService
      .updateVisitDetails(this.visit.visit_id, {
        chief_complaint: this.complaint,
        clinic_id: this.clinic?.id,
        admission_datetime: this.start_date,
        discharge_datetime: this.end_date,
      })
      .subscribe((response: any) => {
        this.processing = false;
        this.updated.emit();
        this.toastr.success('Successfully updated an visit.');
        this.onClose();
      });
  }

  onClose() {
    this.modal = false;
  }
}

<li class="nav-item dropdown ml-3">
  <a class="nav-link" (click)="toggleDropdownMenu()">
    <i class="fas fa-bell text-white"></i>
    <span class="badge badge-warning navbar-badge" style="margin-right: -10px; margin-top: -10px" *ngIf="unreadCount > 0">{{ unreadCount }}</span>
  </a>
  <div #dropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-right pt-4 pr-3 pl-3 pb-3" (scroll)="scroll($event)">
    <div class="d-flex">
      <h5 class="pl-3 bold text-color">Notifications</h5>
      <small class="pr-3 pt-1 bold text-right w-100 cursor-pointer text-info"
        (click)="markAllAsRead()">Mark all as Read</small>
    </div>
    <div>
      <a class="dropdown-item cursor-pointer text-color" *ngFor="let notification of notifications" [routerLink]="[notification?.data?.path]" [queryParams]="convert(notification?.data?.query)" (click)="markAsRead(notification)">
        <div class="d-flex align-items-center">
          <p class="flex-grow-1"><small [class.bold-500]="notification.read_at" [class.bold]="!notification.read_at" [innerHTML]="notification?.data?.body"></small></p>
          <i class="fas fa-circle text-info" *ngIf="!notification.read_at"></i>
        </div>
        <p class="text-muted text-sm"><small>{{ notification?.date  | timeago:live}}</small></p>
      </a>
    </div>
    <div class="p-3" *ngIf="processing">
      <div><p-skeleton height="2.5rem" width="100%"></p-skeleton></div>
      <div class="mt-3" style="opacity: .5;"><p-skeleton height="2.5rem" width="100%"></p-skeleton></div>
    </div>
    <div class="p-3" *ngIf="!processing && notifications?.length < 1">
      <p class="text-color">
        No Notifications
      </p>
    </div>
  </div>
</li>

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { isAcceptableImage } from 'src/app/utils/helper/utilities';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @ViewChild("uploader", { static: false }) uploader: ElementRef;

  @Output()
  preview: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  image: any;

  @Input()
  label: any;

  @Input()
  showUploadButton: boolean = true;

  constructor(
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
  }

  previewUploadFile(event) {
    var id = event.target.id;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (!isAcceptableImage(file?.type)) {
        this.toastr.error('File should be of type image', '', { timeOut: 5000 });
        return;
      }

      reader.readAsDataURL(file);

      reader.onload = () => {
        this.preview.emit({
          file: file,
          name: file?.name,
          preview: this.image = reader.result as string
        })
      };
    }
  }

  open() {
    this.uploader.nativeElement.click();
  }

}

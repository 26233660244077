import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { extendMoment } from 'moment-range';
import * as _moment from 'moment';

@Component({
  selector: 'app-clinic-blocks-schedule',
  templateUrl: './clinic-blocks-schedule.component.html',
  styleUrls: ['./clinic-blocks-schedule.component.scss']
})
export class ClinicBlocksScheduleComponent implements OnInit {


  @Output()
  delete: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  doctor: any;

  @Input()
  form: FormGroup;

  @Input()
  clinic: any;

  @Input()
  clinics: any;

  @Input()
  serviceItems: any;

  @Input()
  serviceForm: FormGroup;

  @Input()
  selectedService: any;

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.form) {
      this.setExceptions();
    }
  }

  ngOnInit(): void {
  }

  setExceptions() {
    this.exceptions.clear();
    this.clinic?.exceptions?.forEach((exception) => {
      this.exceptions.push(this.createException(exception));
    });
  }

  get exceptions() {
    return this.form.get('exceptions') as FormArray;
  }

  addException() {
    this.exceptions.push(this.createException())
  }

  removeException(index) {
    this.delete.emit(this.exceptions?.value?.[index]?.id);
    this.exceptions.removeAt(index)
  }

  isRepeatingChange(index: number) {
    let arr = [...this.form.get('exceptions').value];
    arr[index].is_repeating = !arr[index].is_repeating;
    this.form.get('exceptions').setValue(arr);
  }

  createException(exception = null) {
    return this.fb.group({
      id: [exception?.id],
      clinic_id: [exception?.clinic_id || this.clinic?.id],
      md_id: [exception?.md_id || this.doctor?.md_id],
      exception_id: [exception?.exception_id],
      exception_type: [exception?.exception_type],
      exception_code: [exception?.exception_code],
      exception_text: [exception?.exception_text],
      exception_display: [exception?.exception_display, [Validators.required]],
      exception_note: [exception?.exception_note],
      exception_date: [exception ? [exception?.effective_start_year, exception?.effective_start_month, exception?.effective_start_day]?.join('-') : null, [Validators.required]],
      effective_start_year: [exception?.effective_start_year],
      effective_start_month: [exception?.effective_start_month],
      effective_start_day: [exception?.effective_start_day],
      effective_start_time: [exception?.effective_start_time, [Validators.required]],
      effective_end_year: [exception?.effective_end_year],
      effective_end_month: [exception?.effective_end_month],
      effective_end_day: [exception?.effective_end_day],
      effective_end_time: [exception?.effective_end_time, [Validators.required]],
      is_repeating: [exception?.is_repeating === 1 ? true : false],
      allow_booking: [exception?.allow_booking || 0]
    })
  }

  updateDates(exception: FormGroup) {
    const moment = extendMoment(_moment);
    const now = moment(exception?.value?.exception_date);

    exception.patchValue({
      effective_start_year: now?.format('YYYY'),
      effective_start_month: now?.format('MM'),
      effective_start_day: now?.format('DD'),
      effective_end_year: now?.format('YYYY'),
      effective_end_month: now?.format('MM'),
      effective_end_day: now?.format('DD'),
    });
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray, Validators } from '@angular/forms';
import { MunicipalityService } from 'src/app/utils/services/utilities/municipality.service';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent implements OnInit {
  @Input()
  patientForm: FormGroup;

  @Input()
  disableFields = false;

  @Input()
  patient: any;

  @Input()
  validations: any;

  countries: any;
  regions: any;
  provinces: any;
  municipalities: any;
  barangays: any;

  subscriptions = [];

  constructor(private addressService: MunicipalityService) {}

  ngOnInit(): void {
    this.getCountries();
    this.getRegions();
    this.getProvinces();
    this.getMunicipalities();
    this.getBarangay();
  }

  getCountries() {
    const queryParams: any = {};
    queryParams.order = 'name';
    queryParams.perPage = 'all';
    queryParams.select = 'code,name';

    this.addressService.getCountries(queryParams).subscribe((response: any) => {
      this.countries = response;
    });
  }

  getRegions() {
    const queryParams = {
      order: 'id',
      perPage: 'all',
      select: 'code,name',
    };

    this.addressService.getRegions(queryParams).subscribe((response: any) => {
      this.regions = response;
    });
  }

  getProvinces() {
    const queryParams: any = {};
    queryParams.order = 'display';
    queryParams.perPage = 'all';
    queryParams.select = 'code,display,region';

    this.addressService.getProvinces(queryParams).subscribe((response: any) => {
      this.provinces = response;
    });
  }

  getMunicipalities() {
    const queryParams: any = {};
    queryParams.order = 'display';
    queryParams.perPage = 'all';
    queryParams.select = 'code,province_code,display';

    this.addressService
      .getMunicipality(queryParams)
      .subscribe((response: any) => {
        this.municipalities = response;
      });
  }

  getBarangay() {
    const queryParams: any = {};
    queryParams.order = 'display';
    queryParams.perPage = 'all';
    queryParams.select = 'code,municipal_code,display';

    this.addressService.getBarangay(queryParams).subscribe((response: any) => {
      this.barangays = response;
    });
  }

  filterProvince(index) {
    return this.provinces?.data?.filter((province) => province?.region === this.patientForm?.value?.addresses[index]?.region_code);
  }

  filterMunicipality(index) {
    return this.municipalities?.data?.filter((city) => +city?.province_code === +this.patientForm?.value?.addresses[index]?.state_province_code);
  }

  filterBarangay(index) {
    return this.barangays?.data?.filter((city) => +city?.municipal_code === +this.patientForm?.value?.addresses[index]?.city_code);
  }

  updateDropdowns(index) {
    this.addressArray.at(index).patchValue({
      state_province_code: null,
      barangay_code: null,
      city_code: null
    })
  }

  get addressArray(): FormArray {
    return this.patientForm.get('addresses') as FormArray;
  }

  updatePermantentAdderess() {
    const addresses: any = this.addressArray;
    const presentAddress = addresses.at(0);
    const permantentAddress = addresses.at(1);

    if (permantentAddress.value.is_same_present) {
      const newValue = Object.assign({}, presentAddress.value);
      newValue.is_same_present = true;
      newValue.type_code = 'permanent';
      permantentAddress.patchValue(newValue);
    } else {
      permantentAddress.reset();
      permantentAddress.get('type_code').setValue('permanent');
      permantentAddress.get('is_same_present').setValue(false);
    }
  }

  isRequired(rules: string) {
    return rules ? rules.includes('required') : false;
  }

  getControl(control) {
    return this.patientForm.get(control);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subs) => {
      subs.unsubscribe();
    });
  }
}

import {HttpClient} from '@angular/common/http';
import {appendQueryParams, utilities_url} from '../../helper/url';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocietyService {
  constructor(private http: HttpClient) {
  }

  getSocieties(queryParams = {}): Observable<any> {
    return this.http.get(
      `${utilities_url}/society${appendQueryParams(queryParams)}`
    );
  }
}

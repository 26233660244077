import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mpi_url, pe_dev_api_url, appendQueryParams } from '../../helper/url';
import { BehaviorSubject, Observable  } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class PatientService {

  patient: any;
  patientSubject = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {}

  listPatient(queryParams = {}) {
    return this.http.get(
      `${mpi_url}/patients${appendQueryParams(queryParams)}`
    );
  }

  reports(queryParams = {}) {
    return this.http.get(
      `${mpi_url}/visits/reports${appendQueryParams(queryParams)}`
    );
  }

  ageChart(queryParams = {}) {
    return this.http.get(
      `${mpi_url}/visits/charts/age${appendQueryParams(queryParams)}`
    );
  }

  pediatricChart(queryParams = {}) {
    return this.http.get(
      `${mpi_url}/visits/charts/pediatric${appendQueryParams(queryParams)}`
    );
  }

  listVisits(queryParams = {}) {
    return this.http.get(
      `${mpi_url}/visits${appendQueryParams(queryParams)}`
    );
  }

  findPatient(patientId, queryParams = {}) {
    return this.http.get(
      `${mpi_url}/patients/${patientId}${appendQueryParams(queryParams)}`
    );
  }

  update(patientId, data, queryParams = {}) {
    return this.http.put(`${mpi_url}/patients/${patientId}${appendQueryParams(queryParams)}`, data);
  }

  updatePatient(patientId, data, queryParams = {}) {
    return this.http.put(`${mpi_url}/patients/${patientId}/update-info${appendQueryParams(queryParams)}`, data);
  }

  registerPatient(data, queryParams = {}) {
    return this.http.post(`${mpi_url}/patients/register${appendQueryParams(queryParams)}`, data);
  }
  registerPatient_confirm(data, queryParams = {}) {
    return this.http.post(`${mpi_url}/patients/registerc${appendQueryParams(queryParams)}`, data);
  }

  registerPatientVisit(patientId,data, queryParams = {}) {
    return this.http.post(`${mpi_url}/patients/${patientId}/register${appendQueryParams(queryParams)}`, data);
  }

  updateRegistration(id, data, queryParams = {}) {
    return this.http.put(`${mpi_url}/patients/${id}/update-registration${appendQueryParams(queryParams)}`, data);
  }

  addAsInpatient(patientId, data, queryParams = {}) {
    return this.http.post(`${mpi_url}/patient/${patientId}/inpatient${appendQueryParams(queryParams)}`, data);
  }
  dischagePatient(patientId, data, queryParams = {}) {
    return this.http.put(`${mpi_url}/patient/${patientId}/discharge${appendQueryParams(queryParams)}`, data);
  }

  getUploadUrl() {
    return `${mpi_url}/uploads`;
  }

  getUploadMutlipleUrl() {
    return `${mpi_url}/uploads/multiple`;
  }

  setPatient(patient) {
    this.patientSubject.next(this.patient = patient);
  }

  getPatient() {
    return this.patient;
  }

  deleteInpatientVisit(id, queryParams = {}) {
    return this.http.delete(`${mpi_url}/patient-visit/${id}/deleteInpatient${appendQueryParams(queryParams)}`);
  }

  patientsListExport(queryParams = {}) {
    return this.http.get(`${mpi_url}/patients-list/export${appendQueryParams(queryParams)}`, {
      responseType: 'blob',
    });
  }

  sexChart(queryParams = {}) {
    return this.http.get(
      `${mpi_url}/visits/charts/gender${appendQueryParams(queryParams)}`
    );
  }

  mergeTwoPatients(data) {
    //console.log(data); return false;
    return this.http.post(`${mpi_url}/patients/merge`, data);
  }

  findPEPatient(patientId, queryParams = {}) {
    return this.http.get(
      `${pe_dev_api_url}/api/mpi/patients/${patientId}${appendQueryParams(queryParams)}`
    );
  }

  findPEPatientHealthRecords(patientId) {
    return this.http.get(
      `${pe_dev_api_url}/api/mpi/patients/${patientId}/health-records`
    );
  }

  findPatientWithData(patientId, params: any): Observable<any> {
    return this.http.post(`${mpi_url}/patients/${patientId}/with-data`, params);
  }

}

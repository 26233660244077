import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { AppService } from 'src/app/utils/services/app.service';

@Component({
  selector: 'app-validation-in-progress',
  templateUrl: './validation-in-progress.component.html',
  styleUrls: ['./validation-in-progress.component.scss']
})
export class ValidationInProgressComponent implements OnInit {

  constructor(
    private renderer: Renderer2,
    private appService: AppService,
  ) { }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'login-container');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'login-container');
  }

  logout() {
    this.appService.logout();
  }

}

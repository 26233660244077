import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AnthropometricService } from 'src/app/utils/services/mpi/anthropomethrics.service';
import { extendMoment } from 'moment-range';
import * as _moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/utils/services/app.service';


@Component({
  selector: 'app-basic-anthropometric',
  templateUrl: './basic-anthropometric.component.html',
  styleUrls: ['./basic-anthropometric.component.scss']
})
export class BasicAnthropometricComponent implements OnInit {

  @Input()
  patientID: any;

  page = 1;
  perPage = 5;
  perPages = [5, 10, 25, 50, 100];
  processing = false;
  items: any;

  form: FormGroup;

  constructor(
    private service: AnthropometricService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private _appService: AppService,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.list();
  }

  createForm() {
    const moment = extendMoment(_moment);

    this.form = this.fb.group({
      patient_id: [this.patientID],
      height: [null, [Validators.required]],
      weight: [null, [Validators.required]],
      body_mass_index: [null],
      input_datetime: [moment().format('YYYY-MM-DD hh:mm')],
    })
  }

  list(page = 1) {
    this.processing = true;
    this.service.list({
      page: this.page = page,
      perPage: this.perPage,
      patient_id: this.patientID
    })
      .subscribe((response: any) => {
        this.items = response;

        this.items?.data?.forEach((item) => {
          item.edited_height = item?.height;
          item.edited_weight = item?.weight;
          item.edited_input_datetime = item?.input_datetime;
        });

        this.processing = false;
      }, (err) => {
        this.processing = false;
      });
  }

  create() {
    this.processing = true;
    this.service.save(this.form?.value)
      .subscribe((response: any) => {
        this.processing = false;
        this.toastr.success("Successfully created the measurement");
        this.createForm();
        // this.reload();
        this.list(this.page);
      }, (err) => {
        this.processing = false;
      });
  }

  delete(item) {
    if (confirm("Are you sure you want to remove this measurement?")) {
      this.processing = true;
      this.service.delete(item?.id)
        .subscribe((response: any) => {
          this.processing = false;
          this.toastr.success("Successfully deleted the measurement");
          // this.reload();
          this.list(this.page);
        }, (err) => {
          this.processing = false;
        });
    }
  }

  update(item) {
    this.processing = true;
    this.service.update(item?.id, {
      height: item.edited_height,
      weight: item.edited_weight,
      input_datetime: item.edited_input_datetime
    })
      .subscribe((response: any) => {
        item.edit = false;
        this.toastr.success("Successfully updated the measurement");
        // this.reload();
        this.list(this.page);
      }, (err) => {
        this.processing = false;
      });
  }

  onCancel(item) {
    item.edited_height = item?.height;
    item.edited_weight = item?.weight;
    item.edited_input_datetime = item?.input_datetime;
    item.edit = false;
  }

  getControl(name) {
    return this.form.get(name);
  }

  computeBmi() {
    const height = +this.form.value.height * 0.01;
    this.form?.patchValue({
      body_mass_index: this.getInterpretation(this.form.value.weight / (height * height))
    })
  }

  getInterpretation(bmi) {
    if (bmi < 18.5) {
      return Number(bmi).toFixed(2) + " (Underweight)";
    } else
      if (bmi >= 18.5 && bmi < 25) {
        return Number(bmi).toFixed(2) + " (Normal)";
      } else
        if (bmi >= 25 && bmi < 30) {
          return Number(bmi).toFixed(2) + " (Overweight)";
        } else
          if (bmi >= 30) {
            return Number(bmi).toFixed(2) + " (Obese)";
          } else {
            return Number(bmi).toFixed(2) + " (Normal)";
          }
  }

  reload() {
    this._appService.reload('patient/' + this.patientID + '/anthropometric-measurements');
  }

}

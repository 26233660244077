<p-dialog
    [baseZIndex]="2000"
    [style]="{ maxWidth: '600px', marginTop: '30px'  }"
    [(visible)]="modal"
    [closable]="false"
    [closeOnEscape]="false"
    [dismissableMask]="false"
    [modal]="true"
    [position]="'center'"
    [draggable]="false"
  >
    <div class="pr-3 pl-3 text-center" >
      <span><i class="fas fa-hand-sparkles fa-6x text-primary"></i></span>
      <div class="welcome-text"> <span>Welcome to </span><img src="assets/img/mwell-md-logo-hires.png" alt="mwell-md-logo" class="img-fluid" /></div>
      <div class="row mb-2">
        <div class="col-12 text-lg mb-2"><span>Your quick registration is complete. You can now access our EMR features.</span></div>
        <div class="col-12 text-lg mb-2"><span>Unlock teleconsultation in three(3) easy steps:</span></div>
      </div>
      <div class="row text-primary">
        <div class="col-1"><strong class="text-lg">{{ teleconsultationProgress ? teleconsultationProgress.total_percent : null }}%</strong></div>
        <div class="col mt-1 ml-1">
          <div class="progress" style="height: 22px; border-radius: 10px; border: 1px solid #43C8FF">
            <div class="progress-bar" role="progressbar" style="width: {{ teleconsultationProgress ? teleconsultationProgress.total_percent : null }}%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="container w-75 mt-4">
      <div class="row">
        <div class="col-12 d-flex ">
          <div class="circle" [ngClass]="{'checked': teleconsultationProgress.profile_info}">
            <i *ngIf="teleconsultationProgress.profile_info" class="fa fa-check" [ngClass]="{'teleconsult-progress-checked': teleconsultationProgress.profile_info}"></i>
          </div>
          <span class="text-lg ml-3">Complete Profile Information</span>
        </div>
        <div class="col-12 d-flex my-3">
          <div class="circle" [ngClass]="{'checked': teleconsultationProgress.credentials}">
            <i *ngIf="teleconsultationProgress.credentials" class="fa fa-check" [ngClass]="{'teleconsult-progress-checked': teleconsultationProgress.credentials}"></i>
          </div>
          <span class="text-lg ml-3">Complete Credentials</span>
        </div>
        <div class="col-12 d-flex">
          <div class="circle" [ngClass]="{'checked': teleconsultationProgress.financial}">
            <i *ngIf="teleconsultationProgress.financial" class="fa fa-check" [ngClass]="{'teleconsult-progress-checked': teleconsultationProgress.financial}"></i>
          </div>
          <span class="text-lg ml-3">Complete Payment Information</span>
        </div>
      </div>
    </div>
    <div class="row mt-4 px-4">
      <div class="col-12 text-center">
        <span>Once validated, you'll be ready to offer virtual consultations to our patients through the mWell virtual clinic.</span>
      </div>
    </div>
    <p-footer>
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <a href="javascript:;" class="btn btn-primary bold btn-okay" (click)="onGetStart()">Get Started</a>
        </div>
        <div class="col-12 d-flex justify-content-center pt-3">
          <a href="javascript:;" (click)="modal = false">Skip for now</a>
        </div>
      </div>
    </p-footer>
</p-dialog>
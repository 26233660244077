<p-dialog
  [baseZIndex]="2000"
  [style]="{ minWidth: '600px', marginTop: '30px'  }"
  [(visible)]="modal"
  [closable]="false"
  [closeOnEscape]="false"
  [dismissableMask]="false"
  [modal]="true"
  [position]="'center'"
  [draggable]="false"
>
    <form [formGroup]="form" *ngIf="form">
        <fieldset [disabled]="processing">
            <div class="pr-3 pl-3">
                <h3 class="text-primary bold">Create New Item</h3>
            </div>
            <div class="form-group mt-3">
                <span class="bold-500 text-muted">Item Category</span>
                <b class="ml-2 text-danger" *ngIf="getControl('cat_id')?.errors?.required">*</b>
                <select class="form-control mt-2" formControlName="cat_id" (change)="onSelectCategory()">
                    <option value="null" selected hidden>Select Item Category</option>
                    <option [value]="category?.id" *ngFor="let category of categories?.data">{{ category?.cat_name }}</option>
                </select>
            </div>
            <div class="form-group">
                <span class="bold-500 text-muted">Name</span>
                <b class="ml-2 text-danger" *ngIf="getControl('item_name')?.errors?.required">*</b>
                <input type="text" class="form-control mt-2" formControlName="item_name">
            </div>
            <div class="form-group">
                <span class="bold-500 text-muted">Description</span>
                <b class="ml-2 text-danger" *ngIf="getControl('item_description')?.errors?.required">*</b>
                <input type="text" class="form-control mt-2" formControlName="item_description">
            </div>
            <div class="form-row">
                <div class="col-auto">
                    <span>Price</span>
                    <b class="ml-2 text-danger" *ngIf="getControl('base_price')?.errors?.required">*</b>
                    <input type="text" class="form-control mt-2" formControlName="base_price">
                </div>
                <!-- <div class="col" style="margin-top: 32px;">
                    <label class="switch pt-1">
                        <input type="checkbox" formControlName="pf_tracking">
                        <span class="slider round"></span>
                    </label>
                    <span>
                        is a professional fee
                    </span>
                </div> -->
            </div>
        </fieldset>
    </form>
    <p-footer>
        <div class="w-100 text-right pr-3 pl-3 pb-3">
        <button role="button" class="btn btn-primary btn-lg mr-2" (click)="createItem()" [disabled]="processing || form?.invalid">
            Add New Item
            <i *ngIf="processing" class="ml-2 nav-icon fas fa-circle-notch spinning"></i>
        </button>
        <button role="button" class="btn btn-outline-primary btn-lg" (click)="onClose()" [disabled]="processing">
            Close
        </button>
        </div>
    </p-footer>
</p-dialog>

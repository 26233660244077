import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AttendanceService } from 'src/app/utils/services/doctor/attendance.service';
import { takeUntil } from 'rxjs/operators';
import { Subject  } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as _moment from 'moment';

@Component({
  selector: 'app-notification-status-modal',
  templateUrl: './notification-status-modal.component.html',
  styleUrls: ['./notification-status-modal.component.scss']
})
export class NotificationStatusModalComponent implements OnInit {
  modal = false;
  processing = false;

  @Input()
  status: any;

  @Input()
  title: any;

  @Input()
  message: any;
  
  private unsubscribe$ = new Subject<void>();

  constructor(
  ) { }

  ngOnInit(): void {
  }

  createForm() {
  }

  onOpen() {
    this.modal = true;
  }

  onClose() {
    this.modal = false;
  }

}

import {Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {getStorage} from '../helper/storage';
import {CURRENT_USER} from '../items/storage-names';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit {
  private roles = [];
  private user;
  private isHidden = true;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
  }

  ngOnInit(): void {
    this.user = getStorage(CURRENT_USER, true);
    if (this.user && this.user?.role_name) {
      this.updateView();
    }
  }

  @Input()
  set appHasRole(val) {
    this.roles = val;
    this.updateView();
  }

  private updateView(): void {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission(): boolean {
    let hasRole = false;

    if (this.user?.role_name) {
      hasRole = this.roles.find((role) => role.toLowerCase() === this.user.role_name.toLowerCase());
    }

    return hasRole;
  }
}

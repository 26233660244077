<p-dialog [baseZIndex]="2000" [style]="{ minWidth: '600px', marginTop: '30px'  }" [(visible)]="modal" [closable]="false"
  [closeOnEscape]="false" [dismissableMask]="false" [modal]="true" [position]="'center'" [draggable]="false">
  <form [formGroup]="form" *ngIf="form">
    <div class="pr-3 pl-3">
      <h3 class="text-primary bold">Email to Doctor</h3>
      <div class="form-group mt-4">
        <span class="bold-500 text-muted">Name</span>
        <b class="ml-2 text-danger" *ngIf="getControl('name')?.errors?.required">*</b>
        <input type="email" class="form-control" formControlName="name" placeholder="Enter Doctors Name" />
      </div>     
      <div class="form-group mt-4">
        <span class="bold-500 text-muted">Email</span>
        <b class="ml-2 text-danger" *ngIf="getControl('email')?.errors?.required">*</b>
        <input type="email" class="form-control" formControlName="email" placeholder="Enter Doctors Email"/>
      </div>
    </div>
  </form>

  <p-footer>
    <div class="w-100 text-right pr-3 pl-3 pb-3">
      <button role="button" class="btn btn-primary btn-md mr-2" (click)="send(document)" [disabled]="form?.invalid">
        Send
      </button>
      <button role="button" class="btn btn-outline-primary btn-md" (click)="onClose()">
        Close
      </button>
    </div>
  </p-footer>
</p-dialog>

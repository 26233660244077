import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MunicipalityService } from 'src/app/utils/services/utilities/municipality.service';
import { extendMoment } from 'moment-range';
import * as _moment from 'moment';
import { DoctorService } from 'src/app/utils/services/doctor/doctor.service';
import { CasService } from 'src/app/utils/services/cas.service';
import { UtilitiesService } from 'src/app/utils/services/utilities/utilities.service';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent implements OnInit {
  form: any;
  processing = false;
  isSamePresent: boolean = true;
  countries: any;
  provinces: any;
  municipalities: any;
  barangays: any;
  nationalities: any;
  prcLicense: any;
  user: any;
  doctor: any;
  modal = false;
  isChecklist: boolean = false;
  licenseFile: File | null = null;

  constructor(
    private utilService: UtilitiesService,
    private toastr: ToastrService,
    private router: Router,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private addressService: MunicipalityService,
    private service: DoctorService,
    private casService: CasService,
  ) { }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'login-container');
    this.getUserRegistration();
    this.getEmailStatus();

    this.createForm();
    this.getUtilities();
    this.getCountries();
    this.getProvinces();
    this.getMunicipalities();
    this.getBarangay();
  }

  returnToLogin() {
    console.log('returnToLogin')
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  getUserRegistration() {
    const _user = JSON.parse(localStorage.getItem('user-registration'));
    if (!_user) {
      this.toastr.error("Email address is not found!", '', {
        timeOut: 3000
      });
      this.router.navigate(['/sign-up-doctors']);
    }
    this.user = {
      email: atob(_user.email),
      id: _user?.id ? atob(_user?.id) : '',
      application_checklists: _user?.application_checklists
    }
    if (_user?.id) {
      this.getDoctor();
    }
  }

  getDoctor() {
    this.processing = true;
    this.service.getDoctorByUser(this.user?.id, {
      includes: 'user,medical_documents,prc_license.file,s2_license.file,ptr_license.file,specialties.specialty,subspecialties.specialty,hospital_affiliations.hospital_master,hmo.hmo_master,medical_associations,present_address,permanent_address,contact_email,contact_mobile,birth_place'
    })
      .subscribe((response: any) => {
        this.doctor = response
        this.processing = this.doctor?.user?.account_status === 'pending' ? true : false
        if (this.user?.application_checklists?.length || this.doctor.user.additional_reasons) {
          this.isChecklist = true
        }
        this.createForm();
      }, (err) => {
        this.processing = false;
        console.log(err)
      });
  }

  onChecklist(list) {
    const current_list = this.user?.application_checklists?.filter((item) => item.reason_key.includes(list))
    return current_list?.length ? true : false
  }

  getEmailStatus() {
    this.casService.registerStatus(this.user.email)
      .subscribe((response: any) => {
        if (response?.is_registered) {
          this.toastr.success("Doctor already registered!", '', {
            timeOut: 3000
          });
          this.router.navigate(['/']);
        }
      },
        (err) => {
          this.router.navigate(['/']);
        })
  }

  getUtilities() {
    const includes: any[] = [
      '101', // Marital Status
      '103', // Nationality
      '104', // religion
      '158', // languages
      '106', // Contact Types
      '108', // Purpose of contact
      '107', // Relationship
      '158', // Languanges
      '110', // Identity types
    ];

    const queryParams: any = {};
    queryParams.perPage = 'all';
    queryParams.includes = 'details';
    queryParams.codes = includes.join(',');

    this.utilService.getValueMaster(queryParams)
      .subscribe((response: any) => {
        localStorage.setItem('value_master', JSON.stringify(response?.data));
        setTimeout(() => {
          this.nationalities = this.getMasterByName('Nationality');
        }, 1000);
      },
        (err) => { });
  }

  getCountries() {
    const queryParams: any = {};
    queryParams.order = 'name';
    queryParams.perPage = 'all';
    queryParams.select = 'code,name';

    this.addressService.getCountries(queryParams).subscribe((response: any) => {
      this.countries = response;
    });
  }

  getBarangay() {
    const queryParams: any = {};
    queryParams.order = 'display';
    queryParams.perPage = 'all';
    queryParams.select = 'code,municipal_code,display';

    this.addressService.getBarangay(queryParams).subscribe((response: any) => {
      this.barangays = response;
    });
  }

  getMunicipalities() {
    const queryParams: any = {};
    queryParams.order = 'display';
    queryParams.perPage = 'all';
    queryParams.select = 'code,province_code,display';

    this.addressService
      .getMunicipality(queryParams)
      .subscribe((response: any) => {
        const data = response.data.map((item) => {
          return {
            ...item,
            display: item?.display?.trim()
          }
        }).sort((a, b) => a.display < b.display ? -1 : (a.display > b.display ? 1 : 0))
        this.municipalities = { data };
      });
  }

  getProvinces() {
    const queryParams: any = {};
    queryParams.order = 'display';
    queryParams.perPage = 'all';
    queryParams.select = 'code,display,region';

    this.addressService.getProvinces(queryParams).subscribe((response: any) => {
      this.provinces = response;
    });
  }

  filterMunicipality(obj) {
    return this.municipalities?.data?.filter((city) => +city?.province_code === +this.form?.value[obj]?.province_code);
  }

  filterBarangay(obj) {
    return this.barangays?.data?.filter((city) => +city?.municipal_code === +this.form?.value[obj]?.city_code);
  }

  createForm() {
    const moment = extendMoment(_moment);
    this.form = this.fb.group({
      is_same_present: [this.isSamePresent],
      terms_conditions: [null, [Validators.required]],
      doctor: this.fb.group({
        md_id: [this.doctor?.md_id],
        date_of_birth: [this.doctor?.date_of_birth, [Validators.required]],
        profile_photo_renamed: [this.doctor?.profile_photo_renamed],
        sex_code: [this.doctor?.sex_code],
        e_signature_renamed: [this.doctor?.e_signature_renamed]
      }),
      user: this.fb.group({
        languages_spoken: [],
        account_status: ['pending']
      }),
      name: this.fb.group({
        id: [this.doctor?.primary_name?.id],
        type_code: [this.doctor?.primary_name?.type_code || 'P'],
        type_text: [this.doctor?.primary_name?.type_text || 'Primary'],
        type_display: [this.doctor?.primary_name?.type_display || 'Primary'],
        prefix: [this.doctor?.primary_name?.prefix, [Validators.required]],
        first: [this.doctor?.primary_name?.first, [Validators.required]],
        middle: [this.doctor?.primary_name?.middle],
        last: [this.doctor?.primary_name?.last, [Validators.required]],
        suffix: [this.doctor?.primary_name?.suffix],
        degrees: [this.doctor?.primary_name?.degrees],
        is_default: [this.doctor?.primary_name?.is_default || 1],
        is_primary: [this.doctor?.primary_name?.is_primary || 1]
      }),
      address: this.fb.group({
        line1: [this.doctor?.present_address?.line1, [Validators.required]],
        barangay_code: [this.doctor?.present_address?.barangay_code, [Validators.required]],
        barangay_text: [this.doctor?.present_address?.barangay_text],
        city_code: [this.doctor?.present_address?.city_code, [Validators.required]],
        city_text: [this.doctor?.present_address?.city_text],
        province_code: [this.doctor?.present_address?.state_code, [Validators.required]],
        province_text: [this.doctor?.present_address?.state_text],
        zip_code: [this.doctor?.present_address?.zip_code, [Validators.required]],
      }),
      permanent_address: this.fb.group({
        line1: [this.doctor?.permanent_address?.line1],
        barangay_code: [this.doctor?.permanent_address?.barangay_code],
        barangay_text: [this.doctor?.permanent_address?.barangay_text],
        city_code: [this.doctor?.permanent_address?.city_code],
        city_text: [this.doctor?.permanent_address?.city_text],
        province_code: [this.doctor?.permanent_address?.state_code],
        province_text: [this.doctor?.permanent_address?.state_text],
        zip_code: [this.doctor?.permanent_address?.zip_code],
      }),
      contact_details: this.fb.group({
        email: [this.doctor?.contact_email?.value ?? this.user?.email, [Validators.required, Validators.email]],
        mobile: [this.doctor?.contact_mobile?.value, [Validators.required]]
      }),
      birth_details: this.fb.group({
        md_id: [this.doctor?.birth_place?.md_id],
        place_birth_country: [this.doctor?.birth_place?.country_code, [Validators.required]],
        place_birth_city: [this.doctor?.birth_place?.city_code, [Validators.required]],
        nationality: [this.doctor?.birth_place?.nationality, [Validators.required]]
      }),
      license: this.fb.group({
        id: [this.doctor?.prc_license?.id],
        number: [this.doctor?.prc_license?.number, [Validators.required]],
        validity_start_datetime: [this.doctor?.prc_license?.validity_start_datetime, [Validators.required]],
        validity_expiry_datetime: [this.doctor?.prc_license?.validity_expiry_datetime, [Validators.required]],
        file: this.fb.group({
          id: [this.doctor?.prc_license?.file?.id || 1],
          batch_number: [this.doctor?.prc_license?.file?.batch_number || 1],
          rename: [this.doctor?.prc_license?.file?.rename, [Validators.required]],
          name: [this.doctor?.prc_license?.file?.name, [Validators.required]],
          description: [this.doctor?.prc_license?.file?.description],
          category: [this.doctor?.prc_license?.file?.category || 'license'],
          version: [this.doctor?.prc_license?.file?.version || 1],
        })
      })
    });
  }

  onFormChange(control) {
    const field = this.getControl(control)
    if (control === 'address.province_code') {
      this.form.get('address').patchValue({
        province_text: this.provinces?.data?.find((item) => item?.code === field?.value)?.display ?? '-'
      });

      this.getControl('address.city_code')?.setValue(null);
    } else if (control === 'address.city_code') {
      this.form.get('address').patchValue({
        city_text: this.municipalities?.data?.find((item) => item?.code === field?.value)?.display ?? '-'
      });

      this.getControl('address.barangay_code')?.setValue(null);
    } else if (control === 'address.barangay_code') {
      this.form.get('address').patchValue({
        barangay_text: this.barangays?.data?.find((item) => item?.code === field?.value)?.display ?? '-'
      });
    } else if (control === 'permanent_address.province_code') {
      this.form.get('permanent_address').patchValue({
        province_text: this.provinces?.data?.find((item) => item?.code === field?.value)?.display ?? '-'
      });

      this.getControl('permanent_address.city_code')?.setValue(null);
    } else if (control === 'permanent_address.city_code') {
      this.form.get('permanent_address').patchValue({
        city_text: this.municipalities?.data?.find((item) => item?.code === field?.value)?.display ?? '-'
      });

      this.getControl('permanent_address.barangay_code')?.setValue(null);
    } else if (control === 'permanent_address.barangay_code') {
      this.form.get('permanent_address').patchValue({
        barangay_text: this.barangays?.data?.find((item) => item?.code === field?.value)?.display ?? '-'
      });
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'login-container');
  }

  back() {
    this.router.navigate(['/sign-up-doctors/verify-code']);
  }

  /* setImageControl(file, name, control) {
    this[name] = file;
    this.form.get(control).patchValue({
      name: file?.file?.name,
      rename: file?.preview
    });
    this.form.get('license.file').markAllAsTouched()
  } */

  setImageControl(file, name, control) {
    this[name] = file;
    const formControl = this.form.get(control);

    if (formControl) {
      const value = {
        name: file?.file?.name,
        rename: file?.preview
      };
      console.log('Setting form control value:', value);
      formControl.patchValue(value);
      formControl.markAsTouched();
      this.licenseFile = file.file;
    }

    this.form.get('license.file').markAllAsTouched();
  }

  submit() {
    // Stable working code do not remove
    this.processing = true;
    this.form.markAllAsTouched()
    if (this.form.invalid) {
      this.processing = false;
      return
    }
    // this.modal = true
    if (!this.form.value.terms_conditions) {
      this.toastr.error("Terms and Conditions should be checked!", '', {
        timeOut: 3000
      });
      this.processing = false;
      return
    }

    if (this.isSamePresent) {
      this.form.value.is_same_present = true;
      const newValue = Object.assign({}, this.form.value.address);
      this.form.value.permanent_address = newValue;
    }

    const formData = new FormData();
    formData.append("email", this.user.email);
    formData.append("data", JSON.stringify(this.form.value));
    if (this.prcLicense) {
      formData.append("prc_file", this.prcLicense.file);
    }

    this.service.registerDoctor(formData)
      .subscribe((response) => {
        console.log('registerDoctor response: ', response);
        this.processing = false;
        this.toastr.success("Successfully submitted!");
        setTimeout(() => {
          this.router.navigate(['/sign-up-doctors/verification-in-progress']);
          localStorage.removeItem('user-registration');
        }, 1000);
      }, (err) => {
        this.processing = false;
        console.log('err ', err)
      })
  }

  /* async submit() {
    this.processing = true;
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.processing = false;
      return;
    }
  
    if (!this.form.value.terms_conditions) {
      this.toastr.error("Terms and Conditions should be checked!", '', {
        timeOut: 3000
      });
      this.processing = false;
      return;
    }
  
    if (this.isSamePresent) {
      this.form.value.is_same_present = true;
      const newValue = Object.assign({}, this.form.value.address);
      this.form.value.permanent_address = newValue;
    }
  
    try {
      if (this.licenseFile) {
        console.log('Uploading prc_file:', this.licenseFile);
        const licenseFileForm = new FormData();
        licenseFileForm.append("file", this.licenseFile);
        licenseFileForm.append("mimes", 'mimes:png,jpg,jpeg,png,bmp,gif,webp,image');
        
        const uploadResponse = await this.uploadFile(licenseFileForm).toPromise();
        console.log('File upload response:', uploadResponse);
  
        this.form.patchValue({
          license: {
            file: {
              rename: uploadResponse, 
              name: this.licenseFile.name
            }
          }
        });
      } else {
        console.log('No prc_file to upload');
      } 
  
      const formData = new FormData();
      formData.append("email", this.user.email);
      formData.append("data", JSON.stringify(this.form.value));
  
      const response = await this.service.registerDoctor(formData).toPromise();
      this.processing = false;
      this.toastr.success("Successfully submitted!");
      setTimeout(() => {
        this.router.navigate(['/sign-up-doctors/verification-in-progress']);
        localStorage.removeItem('user-registration');
      }, 1000);
    } catch (err) {
      this.processing = false;
      console.log('Error:', err);
      this.toastr.error("An error occurred during submission.");
    }
  } */

  uploadFile(data: FormData) {
    return this.service.upload(data);
  }

  onModalOk() {
    this.modal = false
    this.router.navigate(['/sign-up-doctors/verification-in-progress']);
  }

  getControl(value) {
    return this.form.get(value);
  }

  toggleSamePresent() {
    this.isSamePresent = !this.isSamePresent
    if (this.isSamePresent) {
      this.form.value.is_same_present = true;
      const newValue = Object.assign({}, this.form.value.address);
      this.form.value.permanent_address = newValue;
    } else {
      this.form.get('permanent_address').reset()
    }
  }

  getMasterByName(name: string) {
    return JSON.parse(localStorage.getItem('value_master'))?.find((item) => {
      return item?.text === name;
    });
  }

  preventZero(event) {
    let value = event.target.value;

    if (/^0/.test(value)) {
      event.target.value = value.replace(/^0/, "")
    }
  }
}

<div>
  <p-dialog [baseZIndex]="2000" [style]="{ minWidth: '1000px', marginTop: '30px'  }" [(visible)]="modal" [closable]="false"
  [closeOnEscape]="false" [dismissableMask]="false" [modal]="true" [position]="'center'" [draggable]="false">
    <ng-template pTemplate="header">
      <h3 class="text-primary bold-700 text-uppercase">Attendance</h3>
    </ng-template>
    
    <form [formGroup]="form" *ngIf="form">
      <fieldset>
          <div class="d-flex mb-4 pt-2 filter-inputs">
            <div style="width: 30%;">
                <span for="status" class="text-primary bold">Filter</span>
                <select class="form-control w-100" (change)="reloadData()"
                    formControlName="dateFilter">
                    <option value="All">All</option>
                    <option value="Week">This week</option>
                    <option value="Month">This month</option>
                </select>
            </div>
            <div class="pl-3" style="width: 30%;">
                <span for="status" class="text-primary bold">Type of Duty</span>
                <select class="form-control w-100" (change)="reloadData()"
                    formControlName="duty">
                    <option value="All">All</option>
                    <option value="Day">Day Shift</option>
                    <option value="Night">Night Shift</option>
                    <option value="Graveyard">Graveyard Shift</option>
                </select>
            </div>
            <div class="position-relative" style="width: 40%;">
                <button type="button" class="btn btn-primary leave-button" (click)="requestLeaveModal?.onOpen()"><i class="fas fa-calendar mr-1"></i> Request Leave</button>
            </div>
            
          </div>
      </fieldset>
    </form>

    
    <div class="row mt-3">
      <div class="col">
          <table class="table attendance-table bold-500">
              <thead>
                  <th class="bg-primary text-left">Date of Duty</th>
                  <th class="bg-primary">Time In</th>
                  <th class="bg-primary">Time Out</th>
                  <th class="bg-primary">Working Hours</th>
              </thead>
              <tbody *ngIf="processing">
                  <tr *ngFor="let row of counter(2)">
                      <td *ngFor="let cell of counter(9)">
                          <p-skeleton height="2rem"></p-skeleton>
                      </td>
                  </tr>
              </tbody>
              <tbody [class.hidden]="processing" *ngFor="let attendance of attendances?.data | paginate : {
                      id: 'paginator_controls',
                      itemsPerPage: attendances?.per_page,
                      currentPage: attendances?.current_page,
                      totalItems: attendances?.total
                  }; let i = index">
                  <td class="text-left">{{ formatAsDateOnly(attendance?.time_in) }}</td>
                  <td class="text-center">{{ formatAsTimeOnly(attendance?.time_in) }}</td>
                  <td class="text-center">{{ formatAsTimeOnly(attendance?.time_out) }}</td>
                  <td class="text-center">{{ attendance?.shift_type }}</td>
              </tbody>
              <tbody [class.hidden]="processing">
                  <tr *ngIf="attendances?.data?.length < 1">
                      <td colspan="8" class="text-center">No Attendances</td>
                  </tr>
              </tbody>
          </table>
      </div>
      
      <div class="row w-100" [class.hidden]="processing">
        <div class="col">
            <div class="document-header align-items-center d-flex justify-content-end">
                <div class="d-flex align-items-center">
                    <select class="form-control ml-3"
                        style="margin-right: -20px; width: 80px; height: 30px; padding-top: 3px">
                        <option [ngValue]="per" *ngFor="let per of perPages">
                            {{ per }}
                        </option>
                    </select>
                    <pagination-controls id="paginator_controls"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
  </div>

    <ng-template pTemplate="footer">
      <button role="button" class="btn btn-lg btn-outline-primary my-0 mx-auto mt-2 d-block" (click)="onClose()">
        Close
      </button>
    </ng-template>
  </p-dialog>
</div>
<app-request-leave-modal [doctorId]="doctorId" #requestLeaveModal></app-request-leave-modal>
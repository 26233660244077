import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { getStorage } from 'src/app/utils/helper/storage';
import { CURRENT_USER } from 'src/app/utils/items/storage-names';
import { DoctorService } from 'src/app/utils/services/doctor/doctor.service';

@Component({
  selector: 'app-doctor-autocomplete',
  templateUrl: './doctor-autocomplete.component.html',
  styleUrls: ['./doctor-autocomplete.component.scss']
})
export class DoctorAutocompleteComponent implements OnInit {

  @Output() search: EventEmitter<any> = new EventEmitter<any>();

  @Output() clear: EventEmitter<any> = new EventEmitter<any>();

  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  @Output() unselect: EventEmitter<any> = new EventEmitter<any>();
  
  results: any;
  selected: any;
  user: any;

  constructor(
    private service: DoctorService
  ) { }

  ngOnInit(): void {
    this.user = getStorage(CURRENT_USER, true);
  }

  onSearch(event) {
    this.service.getDoctors({
      query: event?.query,
      includes: 'user'
    })
    .subscribe((response: any) => {
      this.results = response?.data;
      this.search.emit(response);
    });
  }

  onClear(item) {
    this.clear.emit(item);
  }

  onUnselect(item) {
    this.unselect.emit(item);
  }

  onSelect(item) {
    this.select.emit(item);
  }

}

<div class="video-container draggable minimized" #videoContainer>
  <div class="video-container-header" (mousedown)="startDrag($event, videoContainer)">
    Minimized Video Call
  </div>
  <div class="video-container-content">
    <iframe width="100%" height="100%" allow="camera; microphone"></iframe>
  </div>
  <div class="video-container-footer">
    <button class="btn btn-primary mt-4" (click)="toggleVideoContainer()">
      {{ isTeleconsultation ? 'Maximize Video Call' : 'Return to Patient\'s Teleconsult' }}
    </button>  
  </div>
</div>
import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormService } from 'src/app/utils/services/forms/forms.service';
import { ToastrService } from 'ngx-toastr';
import { DoctorAutocompleteComponent } from '../../doctor-autocomplete/doctor-autocomplete.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss']
})
export class ReferralComponent implements OnInit {

  @Output() doctor: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedDocument: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('doctorComp', { static: true })
  doctorComp: DoctorAutocompleteComponent;

  form: any;
  modal = false;
  document: any;
  files: any;
  selectedDoctor = null;
  byEmail: boolean = false;
  firstTimeInput: boolean = true;
  aggree: boolean = false;

  constructor(
    private fb: FormBuilder,
    private service: FormService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.document = document;
  }

  createForm() {
    this.form = this.fb.group({
      referred_to: [null, [Validators.required]],
      referred_email: [null, [Validators.required, Validators.email]],
      reason_for_referral: [null, [Validators.required]],
      complaint: [null, [Validators.required]],
      pertinent_information: [null, [Validators.required]]
    });
  }

  send(document) {
    let valid = !this.isEmptyControlValue('reason_for_referral') && !this.isEmptyControlValue('complaint')
      && !this.isEmptyControlValue('pertinent_information');

    valid = (valid && !this.isEmptyControlValue('referred_to')) || valid && !this.isEmptyControlValue('referred_email');

    if (!valid) {
      this.form.markAllAsTouched();
    } else {
      this.form.disable();
      this.form.markAsUntouched();
      this.selectedDocument.emit(document);
      this.doctor.emit(this.form);
      this.modal = false;
    }
  }

  isEmptyControlValue(controlName: string) {
    return !(this.form.get(controlName).value != null && this.form.get(controlName).value.toString().trim().length > 0);
  }

  getControl(name) {
    return this.form.get(name);
  }

  setDoctor(doctor) {
    this.form.patchValue({
      referred_to: doctor?.md_id
    })
  }

  clearDoctor() {
    this.form.patchValue({
      referred_to: null
    })
  }

  toggleReferredBy() {
    this.byEmail = !this.byEmail;
    this.firstTimeInput = true;
    this.form.get('referred_to').value = null;
    this.form.get('referred_email').value = null;
  }

}
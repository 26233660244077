export const dev_url = 'https://mwellmd-csog.azurewebsites.net';

export const environment = {
  production: true,
  cas_url: dev_url + '/cas-api',
  cas_ver: '/api/v1',
  mpi_url: dev_url + '/mpi-api',
  mpi_ver: '/api/v1',
  doctor_url: dev_url + '/doctor-api',
  doctor_ver: '/api/v1',
  utilities_url: dev_url + '/utilities-api',
  utilities_ver: '/api/v1',
  forms_url: dev_url + '/forms-api',
  forms_ver: '/api/v1',
  schedule_url: dev_url + '/schedule-api',
  schedule_ver: '/api/v1',
  socket_io_url: dev_url + ':6001',
  acs_connection_string: 'endpoint=https://mp-coms-001.communication.azure.com/;accesskey=Q6/Z4xfLTr2yHAsQ8jfep8B7Nr6lehygP+/J4tT8H76An3I/wbzAnLcwOSY+Sqe971CXisJtgm0lT8LHg1bWOg==',

  // pusher info,
  pusher_app_cluster: 'ap1',
  pusher_app_id: '1410527',
  pusher_app_key: 'b6bd23aa92eb2e767cc0',
  pusher_app_secret: 'b5b8d70cd9845185deb4',

  //recaptcha
  recaptch_site_key: '6LfiwUcgAAAAAJV2iWfBzZFIK5HqYVSy_PpXtary',
  recaptcha_secret_key: '6LfiwUcgAAAAAEte6ZLGKjbepfQ3-iJYC6iL7qHc',

  // telemedicine credentials
  mwell_basic_api: 'https://apimgmt-mwelltelemed-hippo-001.azure-api.net/api/v1',
  mwell_telemend_url: 'https://apimgmt-mwelltelemed-hippo-001.azure-api.net/api/v1/web/doctors_onboarding',
  mwell_telemend_subscription_key: 'a22488b80fd3403293de4b7f810a22c6',
  mwell_telemend_key: 'mwellmd',
  mwell_telemend_secret: 'erHnF8YU2Lbt79tTBBvR',
  mwell_telemend_renew_time: 50,
  mwellmd_emr: 'https://emr.mwellmd.com.ph',
  mwell_basic: 'https://appsvc-backoffice-galen-001.azurewebsites.net',
  adb2c_auth_url: "https://mwelltelemedapp.b2clogin.com/mwelltelemedapp.onmicrosoft.com/B2C_1_Authentication_Doctor/oauth2/v2.0/authorize?client_id=bc86f04f-18fd-411e-8e45-f2e479a9d8cb&redirect_uri=" + dev_url + "&scope=bc86f04f-18fd-411e-8e45-f2e479a9d8cb%20offline_access&response_type=code",
  pe_dev_api_url: 'https://mwellpe-csog.azurewebsites.net/apis',
  top_dev_api_url: 'https://mwell-doctor-api-dev.topdevs.co',
  top_dev_x_api_key: '4a28f0177f63b2c9a20e2b9987e670d4',
  // url added to logout to also logout adb2c
  adb2c_logout_url: ''
};

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { getStorage } from '../helper/storage';
import { TELEMED_CRED } from '../items/storage-names';
import { TeleconsultService } from '../services/teleconsult.service';
import { extendMoment } from 'moment-range';
import * as _moment from 'moment';
import { environment } from 'src/environments/environment';
 
@Injectable({
  providedIn: 'root'
})
export class TeleconsultResolverService implements Resolve<any> {

  constructor(
    private service: TeleconsultService
    ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {

    const creds = getStorage(TELEMED_CRED, true);
    const moment = extendMoment(_moment);

    if(!creds || moment(creds?.last_retrieved).isBefore(moment().subtract(environment.mwell_telemend_renew_time,'minutes'))) {
      return this.service.authenticate({
          client_secret: environment.mwell_telemend_secret,
          client_id: environment.mwell_telemend_key
      }).pipe(
        tap((data) => {
          const moment = extendMoment(_moment);
          localStorage.setItem(TELEMED_CRED, JSON.stringify({
            token: data?.d,
            last_retrieved: moment().format("YYYY-MM-DD HH:mm:ss")
          }));
        })
      );
    } else {
      return creds;
    }

    // return this.service.authenticate({
    //   client_secret: environment.mwell_telemend_secret,
    //   client_id: environment.mwell_telemend_key
    // });
  }

}
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AttendanceService } from 'src/app/utils/services/doctor/attendance.service';
import { takeUntil } from 'rxjs/operators';
import { Subject  } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as _moment from 'moment';

@Component({
  selector: 'app-attendance-modal',
  templateUrl: './attendance-modal.component.html',
  styleUrls: ['./attendance-modal.component.scss']
})
export class AttendanceModalComponent implements OnInit {
  modal = false;
  processing = false;
  form: FormGroup;
  attendances: any;
  page = 1;
  perPage = 5;
  perPages = [5, 10, 25, 50, 100];

  @Input()
  doctorId: any;
  
  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private attendanceService: AttendanceService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      dateFilter: ["All"],
      duty: ["All"]
    });
  }

  getAttendances() {
    const queryParams = {
      md_id: this.doctorId,
      open_attendance: false
    };

    this.attendanceService.getAttendances(queryParams)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: any) => {
          if (response.data && response.data.length > 0) {
            this.attendances = response;
            if (this.form.get('dateFilter').value != 'All'){
              if (this.form.get('dateFilter').value == 'Week'){
                this.attendances.data = this.attendances?.data?.filter((attendance) => { return _moment().isSame(this.formatAsDateOnly(attendance?.time_in), 'week') });
              }
              else if (this.form.get('dateFilter').value != 'Month'){
                this.attendances.data = this.attendances?.data?.filter((attendance) => { return _moment().isSame(this.formatAsDateOnly(attendance?.time_in), 'month') });
              }
            }
            if (this.form.get('duty').value != 'All'){
              this.attendances.data = this.attendances?.data?.filter((attendance) => { return attendance.shift_type == this.form.get('duty').value });
            }
          }
        },
        (error) => {
          console.error('Error fetching attendances:', error);
          this.toastr.error('Error fetching attendance data');
        }
      );
  }

  formatAsDateOnly(timestamp = null){
    return _moment(timestamp).format('LL');
  }

  formatAsTimeOnly(timestamp = null){
    return _moment(timestamp).format('h:mm:ss a');
  }

  onOpen() {
    this.modal = true;
    this.getAttendances();
  }

  reloadData(){
    this.getAttendances();
  }

  onClose() {
    this.modal = false;
  }

}

<form [formGroup]="patientForm" *ngIf="patientForm">
  <fieldset [disabled]="disableFields">
    <div class="row equal">
      <div class="col-sm-2 pt-3">
        <span>Patient Photo</span>
        <app-upload-file #uploaders [name]="'file'" [url]="uploadUrl" accept="image/*" [folder]="'photos'"
          uploadButtonPosition="bottom" [disk]="'public'" [disableFields]="disableFields" chooseLabel="Upload"
          [uploadedFiles]="
              patientForm.value.patient.photo
                ? [patientForm.value.patient.photo]
                : []
            " (upload)="setUploadPhoto($event)" (remove)="removeUploadPhoto($event)"></app-upload-file>
      </div>
      <div class="col-sm-10">
        <div class="row hidden" formGroupName="patient">
          <div class="col-4">
            <span>PIN</span>
            <input type="text" class="form-control" placeholder="" formControlName="patient_id" disabled />
          </div>
          <!-- <div class="col-3">
              <div class="form-group" style="padding-top: 30px">
                <div class="custom-control custom-checkbox">
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    id="newborn"
                    formControlName="is_newborn"
                  />
                  <label for="newborn" class="custom-control-label">
                    <span style="font-weight: none !important">Newborn</span>
                  </label>
                </div>
              </div>
            </div> -->
        </div>
        <div class="row mt-3" formGroupName="name">
          <div class="col-1 col-md-2 col-xs-12 col-sm-6">
            <span>Prefix/Title</span><b class="text-red ml-2" *ngIf="
                  !disableFields && getControl('name.prefix')?.errors?.required
                ">*</b>
            <select class="form-control" formControlName="prefix">
              <option value="null" hidden>Select</option>
              <option value="Mr.">Mr.</option>
              <option value="Ms.">Ms.</option>
              <option value="Mrs.">Mrs.</option>
              <option value="Dr.">Dr.</option>
              <option value="Atty.">Atty.</option>
              <option value="Engr.">Engr.</option>
            </select>
          </div>
          <div class="col-4 col-md-3 col-xs-12 col-sm-6">
            <span>First Name</span><b class="text-red ml-2" *ngIf="
                  !disableFields && getControl('name.first')?.errors?.required
                ">*</b>
            <input type="text" class="form-control" placeholder="" formControlName="first" />
          </div>
          <div class="col-3">
            <span>Middle Name</span><b class="text-red ml-2" *ngIf="
                  !disableFields && getControl('name.middle')?.errors?.required
                ">*</b>
            <input type="text" class="form-control" placeholder="" formControlName="middle" />
          </div>
          <div class="col-4">
            <span>Last Name</span><b class="text-red ml-2" *ngIf="
                  !disableFields && getControl('name.last')?.errors?.required
                ">*</b>
            <input type="text" class="form-control" placeholder="" formControlName="last" />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-1 col-md-2 col-sm-6" formGroupName="name">
            <span>Suffix</span><b class="text-red ml-2" *ngIf="
                  !disableFields && getControl('name.suffix')?.errors?.required
                ">*</b>
            <!-- <input type="text" class="form-control" placeholder="" formControlName="suffix" /> -->
            <select class="form-control" formControlName="suffix">
              <option value="null" hidden>None</option>
              <option value="">None</option>
              <option *ngFor="let s of suffix" [value]="s.value">
                {{ s.display }}
              </option>
            </select>
          </div>
          <div class="col-4 col-md-3 col-sm-6" formGroupName="patient">
            <span>Nickname</span><b class="text-red ml-2"
              *ngIf="!disableFields && getControl('name.sex_code')?.errors?.required">*</b>
            <input type="text" class="form-control" placeholder="" formControlName="nickname" />
          </div>
          <div class="col-3" formGroupName="patient">
            <span>Sex</span><b class="text-red ml-2"
              *ngIf="!disableFields && getControl('patient.sex_code')?.errors?.required">*</b>
            <select class="form-control" formControlName="sex_code">
              <option value="null" hidden>None</option>
              <option *ngFor="let gender of sex" [value]="gender.value">
                {{ gender.display }}
              </option>
            </select>
          </div>
          <!-- <div class="col-3" formGroupName="patient">
            <span>Date of Birth</span><b class="text-red ml-2"
              *ngIf="!disableFields && getControl('patient.date_of_birth')?.errors?.required || getControl('patient.date_of_birth')?.errors?.invalidDate">*</b>
            <input type="date" id="dob" class="form-control"
              [class.is-invalid]="getControl('patient.date_of_birth')?.errors?.invalidDate"
              formControlName="date_of_birth" (change)="generateAge()" max="9999-12-31" />
          </div> -->
          <div class="col-3 container" formGroupName="patient">
            <span>Date of Birth</span>
            <div class="input-row datepicker">
              <span class="text-red ml-2"
                *ngIf="!disableFields && getControl('patient.date_of_birth')?.errors?.required || getControl('patient.date_of_birth')?.errors?.invalidDate">*</span>
              <input matInput [matDatepicker]="dobPicker" formControlName="date_of_birth" (dateChange)="generateAge()"
                class="form-control" [class.is-invalid]="getControl('patient.date_of_birth')?.errors?.invalidDate"
                max="9999-12-31">
              <mat-datepicker-toggle matSuffix [for]="dobPicker" style="color: #000000"></mat-datepicker-toggle>
              <mat-datepicker #dobPicker></mat-datepicker>
            </div>
          </div>
          <div class="col-1">
            <span>Age</span>
            <input type="text" class="form-control" placeholder="" [(ngModel)]="age"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!patientForm?.value?.patient?.is_fictitious_date_of_birth" (change)="generateYear()" />
          </div>
          <div class="col-1" *ngIf="patientForm?.value?.patient?.is_newborn">
            <span>M/o</span>
            <input type="text" class="form-control" [(ngModel)]="possibleMonth" [ngModelOptions]="{ standalone: true }"
              disabled />
          </div>
          <div class="col-1" *ngIf="patientForm?.value?.patient?.is_newborn">
            <span>day</span>
            <input type="text" class="form-control" [(ngModel)]="possibleDay" [ngModelOptions]="{ standalone: true }"
              disabled />
          </div>
          <!-- <div class="col-3" formGroupName="patient">
              <div class="form-group" style="padding-top: 30px">
                <div class="custom-control custom-checkbox">
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    id="admitted-inpatient"
                    value="option1"
                    formControlName="is_fictitious_date_of_birth"
                  />
                  <label for="admitted-inpatient" class="custom-control-label">
                    <span style="font-weight: none !important"
                      >Fictitous Date of Birth</span
                    >
                  </label>
                </div>
              </div>
            </div> -->
        </div>
        <div class="row mt-3" formArrayName="attributes">
          <div class="col" formGroupName="0">
            <span>Nationality</span><b class="text-red ml-2"
              *ngIf="!disableFields && getControl('attributes.value_code')?.errors?.required">*</b>
            <select class="form-control" formControlName="value_code" [ngModelOptions]="{ standalone: true }"
              (change)="updateAttribute(0, 'nationalities')">
              <option value="null" hidden>None</option>
              <option [value]="master.code" *ngFor="let master of nationalities?.details">
                {{ master.text }}
              </option>
            </select>
          </div>
          <div class="col" formGroupName="1">
            <span>Civil Status</span><b class="text-red ml-2"
              *ngIf="!disableFields && getControl('attributes.value_code')?.errors?.required">*</b>
            <select class="form-control" formControlName="value_code" [ngModelOptions]="{ standalone: true }"
              (change)="updateAttribute(1, 'civilStatuses')">
              <option value="null" hidden>None</option>
              <option [value]="master.code" *ngFor="let master of civilStatuses?.details">
                {{ master?.text }}
              </option>
            </select>
          </div>
          <div class="col" formGroupName="2">
            <span>Religion</span><b class="text-red ml-2"
              *ngIf="!disableFields && getControl('attributes.value_code')?.errors?.required">*</b>
            <select class="form-control" formControlName="value_code" [ngModelOptions]="{ standalone: true }"
              (change)="updateAttribute(2, 'religions')">
              <option value="null" hidden>None</option>
              <option [value]="master.code" *ngFor="let master of religions?.details">
                {{ master?.text }}
              </option>
            </select>
          </div>
        </div>
        <!-- <div class="row mt-5" formGroupName="alias">
            <div class="col-12">
              <div class="form-group">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="alias"
                    [(ngModel)]="hasAlias"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="updateAlias()"
                  />
                  <label class="custom-control-label" for="alias"
                    >Alias / Fictitious Name</label
                  >
                </div>
              </div>
            </div>
            <div class="col-auto">
              <span>Prefix/Title</span
              ><b
                class="text-red ml-2"
                *ngIf="
                  !disableFields && getControl('alias.prefix')?.errors?.required
                "
                >*</b
              >
              <select class="form-control" formControlName="prefix">
                <option value="null" hidden>None</option>
                <option value="Mr.">Mr.</option>
                <option value="Ms.">Ms.</option>
              </select>
            </div>
            <div class="col">
              <span>First Name</span
              ><b
                class="text-red ml-2"
                *ngIf="
                  !disableFields && getControl('alias.first')?.errors?.required
                "
                >*</b
              >
              <input
                type="text"
                class="form-control"
                placeholder=""
                formControlName="first"
              />
            </div>
            <div class="col">
              <span>Middle Name</span
              ><b
                class="text-red ml-2"
                *ngIf="
                  !disableFields && getControl('alias.middle')?.errors?.required
                "
                >*</b
              >
              <input
                type="text"
                class="form-control"
                placeholder=""
                formControlName="middle"
              />
            </div>
            <div class="col">
              <span>Last Name</span
              ><b
                class="text-red ml-2"
                *ngIf="
                  !disableFields && getControl('alias.last')?.errors?.required
                "
                >*</b
              >
              <input
                type="text"
                class="form-control"
                placeholder=""
                formControlName="last"
              />
            </div>
            <div class="col-1">
              <span>Suffix</span
              ><b
                class="text-red ml-2"
                *ngIf="
                  !disableFields && getControl('name.suffix')?.errors?.required
                "
                >*</b
              >
              <input
                type="text"
                class="form-control"
                placeholder=""
                formControlName="suffix"
              />
              <input
                type="hidden"
                class="form-control"
                placeholder=""
                formControlName="is_primary"
              />
            </div>
          </div> -->
      </div>
    </div>
  </fieldset>
</form>
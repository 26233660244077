import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { week } from 'src/app/utils/items/week';
import * as _moment from 'moment';

@Component({
  selector: 'app-clinic-regular-availability',
  templateUrl: './clinic-regular-availability.component.html',
  styleUrls: ['./clinic-regular-availability.component.scss']
})
export class ClinicRegularAvailabilityComponent implements OnInit {

  @Output()
  deleteSlot: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  doctor: any;

  @Input()
  form: FormGroup;

  @Input()
  clinic: any;

  @Input()
  clinics: any;

  @Input()
  serviceItems: any;

  @Input()
  serviceForm: FormGroup;

  @Input()
  selectedService: any;

  days = week;
  
  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.selectedService && this.form) {
      this.setSchedules();
    }
    if(changes?.form) {
      this.setSchedules();
    }
  }

  setSchedules() {
    const clinicService = this.clinic?.resource?.services?.find((service) => service?.service_item_code === this.selectedService?.service_item_code);

    this.schedules?.controls?.forEach((scheduleControl) => {
      const schedule = clinicService?.regular_schedules?.find((sched) => sched?.schedule_day === scheduleControl?.value?.schedule_day);
      const slots = scheduleControl?.get('slots') as FormArray;
      slots.clear();
      
      schedule?.slots?.forEach((slot) => {
        slots?.push(this.createSlot(slot));
      });
    });
  }

  get schedules() {
    return this.serviceForm.get('regular_schedules') as FormArray;
  }

  getSchedule(index) {
    return this.schedules.at(index);
  }

  addSlot(scheduleIndex) {
    const slots = this.schedules.at(scheduleIndex).get('slots') as FormArray;
    
    slots.push(this.createSlot());
  }

  removeSlot(scheduleIndex, slotIndex) {
    const slots = this.schedules.at(scheduleIndex).get('slots') as FormArray;
    this.deleteSlot.emit(slots?.value?.[slotIndex]?.id);
    slots.removeAt(slotIndex);
  }

  getScheduleControl(index) {
    return this.schedules.at(index);
  }

  getDayIndex(day) {
    return this.schedules?.value?.findIndex((item) => item?.schedule_day === day);
  }

  createSlot(slot = null) {
    return this.fb.group({
      id: [slot?.id],
      slot_type: ['generic'],
      slot_start_time: [slot?.slot_start_time, Validators.required],
      slot_end_time: [slot?.slot_end_time, Validators.required],
      slot_duration: [slot?.slot_duration || 1, Validators.required],
      slot_notes: [slot?.slot_notes],
      number_of_bookings: [slot?.number_of_bookings || 1],
      allow_overbooking: slot?.allow_overbooking || [0],
      override_generic: [slot?.override_generic || 0],
    });
  }

}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { getStorage } from 'src/app/utils/helper/storage';
import { CURRENT_USER } from 'src/app/utils/items/storage-names';
import { PatientService } from 'src/app/utils/services/mpi/patient.service';

@Component({
  selector: 'app-patient-autocomplete',
  templateUrl: './patient-autocomplete.component.html',
  styleUrls: ['./patient-autocomplete.component.scss']
})
export class PatientAutocompleteComponent implements OnInit {

  @Output() search: EventEmitter<any> = new EventEmitter<any>();

  @Output() clear: EventEmitter<any> = new EventEmitter<any>();

  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  @Output() unselect: EventEmitter<any> = new EventEmitter<any>();
  
  results: any;
  selected: any;
  user: any;

  constructor(
    private service: PatientService
  ) { }

  ngOnInit(): void {
    this.user = getStorage(CURRENT_USER, true);
  }

  onSearch(event) {
    this.service.listPatient({
      query: event?.query,
      md_id: this.user?.doctor?.md_id
    })
    .subscribe((response: any) => {
      this.results = response?.data;
      //console.log(this.results);
    });
  }

  onClear(item) {
    this.clear.emit(item);
  }

  onUnselect(item) {
    this.unselect.emit(item);
  }

  onSelect(item) {
    this.select.emit(item);
  }

}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, ReplaySubject, of } from 'rxjs';
import { getStorage } from '../helper/storage';
import { CURRENT_USER, TOKEN, OTP_VERIFIED, IDLE_TIME, IDLE_TIME_LIMIT } from '../items/storage-names';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  currentUser: any;
  _currentUser: any = new BehaviorSubject<any>(null);

  private _user: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _doctor: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private socialAuthService: SocialAuthService,
  ) {
    this.currentUser = this.getCurrentUser();
  }

  getCurrentUser() {
    return getStorage(CURRENT_USER, true);
  }

  /**
  * @deprecated assign value to user and persist using storeUser
  */
  setUser(user) {
    this._currentUser.next(this.currentUser = user);
  }

  set user(value: any) {
    this._user.next(value);
  }

  get user$(): Observable<any> {
    return this._user.asObservable();
  }

  set doctor(value: any) {
    this._doctor.next(value);
  }

  get doctor$(): Observable<any> {
    return this._doctor.asObservable();
  }

  login(token) {
    localStorage.setItem(TOKEN, token);
    this.router.navigate(['/dashboard']);
  }

  logout(redirect: boolean = true) {
    // this.socialAuthService.signOut(true);
    this.currentUser = null;
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(CURRENT_USER);
    localStorage.removeItem(OTP_VERIFIED);
    localStorage.removeItem(IDLE_TIME);
    localStorage.removeItem(IDLE_TIME_LIMIT);

    if (redirect) {
      setTimeout(() => {
        window.location.href = environment.adb2c_logout_url;
      }, 1);
    }
  }

  storeUser(user) {
    localStorage.setItem(CURRENT_USER, JSON.stringify(user));
  }

  updateUserSubscription(subscription: any) {
    this.currentUser = this.getCurrentUser();
    this.currentUser.active_subscription = subscription;
    this.storeUser(this.currentUser);
    return this.currentUser;
  }

  reload(routerUrl: string) {
    const url = routerUrl;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(url);
    });

  }
}

import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { extendMoment } from 'moment-range';
import * as _moment from 'moment';
import { PatientService } from 'src/app/utils/services/mpi/patient.service';
import { FormBuilder } from '@angular/forms';
import { getStorage } from 'src/app/utils/helper/storage';
import { CURRENT_USER } from 'src/app/utils/items/storage-names';
@Component({
  selector: 'app-discharge-modal',
  templateUrl: './discharge-modal.component.html',
  styleUrls: ['./discharge-modal.component.scss']
})
export class DischargeModalComponent implements OnInit {

  @Output()
  discharge: EventEmitter<any> = new EventEmitter<any>();

  user: any;
  modal = false;
  date: any;
  remarks: any;

  item: any;
  patient: any;
  visit: any;
  searching = false;
  processing = false;
  editMode: Boolean;

  constructor(
    private service: PatientService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.user = getStorage(CURRENT_USER, true);
    this.editMode = false;
  }

  onOpen(visit) {
      this.visit = visit;
      this.patient = visit?.patient;

      if(this.editMode){
        this.remarks = null;

        const moment = extendMoment(_moment);
        this.date = moment().format('YYYY-MM-DDTHH:mm');
      } else {
        this.remarks = null;
        const moment = extendMoment(_moment);
        this.date = moment().format('YYYY-MM-DDTHH:mm');
      }
        
    this.searching = false;
    this.processing = false;
    this.modal = true;
  }

  onClose() {
    this.modal = false;
  }

  dischargePatient() {

    this.service.dischagePatient(this.patient?.patient_id, {
      patient_id: this.patient?.patient_id,
      discharge_datetime: this.date,     
      discharge_remarks: this.remarks,
      visit_id: this.visit.visit_id,
      visit: this.visit
    }).subscribe((response: any) => {
      this.modal = false;
      this.discharge.emit();
      this.toastr.success("Successfully discharge inpatient")
    }, (err) => {
      this.processing = false;
    });

  }

}

<div class="container-fluid">
    <div class="row">
        <div class="col">
            <h3 class="text-muted">Specific Clinic Schedule</h3>
            <p class="text-muted">Use this to set schedules for a <strong >specific date and time</strong> that are not part of your regular schedules.</p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <p-accordion [formGroup]="serviceForm">
                <p-accordionTab header="{{ (schedule?.value?.schedule_date | date: 'mediumDate') || 'Expand to edit' }}" *ngFor="let schedule of schedules?.controls; let i = index" formArrayName="specific_schedules">
                    <div class="pr-3 pl-3">
                        <div class="form-group" [formGroup]="schedule">
                            <label>Specific Date</label>
                            <input [class.required-border]="schedule?.get('schedule_date')?.errors?.required" type="date" class="form-control" formControlName="schedule_date" [class.required-border]="schedule?.get('schedule_date')?.errors?.required">
                        </div>
                        <hr>
                        <table class="table table-striped mb-3">
                            <thead>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Schedule Intervals</th>
                                <th>Note</th>
                                <th></th>
                            </thead>
                            <tbody [formGroup]="schedule">
                                <tr *ngFor="let slot of schedule?.controls?.slots?.controls; let j = index" formArrayName="slots" >
                                    <td [formGroup]="slot"><input disabled [class.required-border]="slot?.get('slot_start_time')?.errors?.required" type="time" data-target="#reservationdate" data-form-type="date" class="form-control datetimepicker-input" formControlName="slot_start_time"></td>
                                    <td [formGroup]="slot"><input disabled [class.required-border]="slot?.get('slot_end_time')?.errors?.required" type="time" data-target="#reservationdate" data-form-type="date" class="form-control datetimepicker-input" formControlName="slot_end_time"></td>
                                    <td [formGroup]="slot">
                                        <select disabled [class.required-border]="slot?.get('slot_duration')?.errors?.required" id="exampleFormControlSelect1" class="form-control" formControlName="slot_duration">
                                            <option [value]="0.167">Every 10 mins</option>
                                            <option [value]=".25">Every 15 mins</option>
                                            <option [value]=".5">Every 30 mins</option>
                                            <option [value]="1" selected>Every 1 hour</option>
                                            <option [value]="2" selected>Every 2 hours</option>
                                        </select>
                                    </td>
                                    <td [formGroup]="slot"><input [class.required-border]="slot?.get('slot_notes')?.errors?.required" type="text" placeholder="Notes" class="form-control" formControlName="slot_notes"></td>
                                    <td [formGroup]="slot">
                                        <button class="btn btn-danger" (click)="removeSlot(schedule,j)">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="schedule?.value?.slots?.length < 1">
                                    <td colspan="5">No Slots</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="mb-3">
                            <small class="text-danger bold" *ngIf="schedule?.get('slots')?.errors?.required">At least 1 time slot is required</small>
                        </div>
                    </div>
                    <div class="pl-3 pb-3 pr-3">
                        <button class="btn btn-primary mr-2 hidden" (click)="addSlot(schedule)"><i class="fas fa-plus mr-2"></i>Add Slot</button>
                        <button class="btn btn-primary mr-2" (click)="generator?.onOpen(schedule)"><i class="fas fa-clock mr-2"></i>Create Schedule</button>
                        <button class="btn btn-danger" (click)="removeSchedule(i)">
                            <i class="fas fa-trash mr-2"></i> Delete Schedule
                        </button>
                    </div>
                </p-accordionTab>
            </p-accordion>
            <div *ngIf="schedules?.value?.length < 1" class="p-2" style="border: 1px dashed #c3c3c3; border-radius: 5px;">
                No Schedule/s
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col text-right">
            <button class="btn btn-primary" (click)="addSchedule()">Add Schedule</button>
        </div>
    </div>
</div>
<app-clinic-time-block-generator #generator></app-clinic-time-block-generator>

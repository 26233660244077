<p-dialog [baseZIndex]="2000" [style]="{ minWidth: '600px', marginTop: '30px'  }" [(visible)]="modal" [closable]="false"
    [closeOnEscape]="false" [dismissableMask]="false" [modal]="true" [position]="'center'" [draggable]="false">
    <fieldset>
        <div *ngIf="!loadingForm" class="pr-3 pl-3">
            <h3 class="text-primary bold">Reschedule Appointment</h3>
            <div class="form-group mt-2 mb-2">
                <small class="text-muted">Patient</small>
                <div class="readonly-fields name">{{ appointment?.patient?.display }}</div>
            </div>
            <div class="form-group">
                <small class="text-muted">
                    Clinic <b *ngIf="!clinic" class="ml-2 text-danger">*</b></small>
                <!-- <select class="form-control" [(ngModel)]="clinic"
                    (change)="slot = null; service = null; autoSelectService(); getServiceSchedule()"> -->

                <select class="form-control" [(ngModel)]="clinic" (change)="clinicChange()">
                    <option value="null" hidden selected>Select Clinic</option>
                    <option [ngValue]="clinic" *ngFor="let clinic of clinics">{{ clinic?.name_display }} ({{
                        clinic?.type_text }})</option>
                </select>
            </div>
            <div class="form-group">
                <small class="text-muted">Service<b *ngIf="!service" class="ml-2 text-danger">*</b></small>
                <select class="form-control" [(ngModel)]="service" [disabled]="true">
                    <option value="null" hidden selected>Select Service</option>
                    <!-- <option [ngValue]="service" *ngFor="let service of clinic?.resource?.services">{{
                        service?.service_item?.service_item_name }}</option> -->
                    <ng-container *ngFor="let service of clinic?.resource?.services">
                        <option *ngIf="service.service_item_code === service?.service_item_code" [ngValue]="service">
                            {{ service?.service_item?.service_item_name }}
                        </option>
                    </ng-container>
                </select>
            </div>
            <div class="form-group">
                <small class="text-muted">Current Appointment Date</small>
                <input type="text" class="form-control" [disabled]="true"
                    [value]="appointment?.appointment_datetime_notification">
            </div>
            <div class="form-group">
                <small class="text-muted">Reason for Consultation <b class="ml-2 text-danger"
                        *ngIf="!complaint">*</b></small>
                <input type="text" class="form-control" [(ngModel)]="complaint" [disabled]="!service">
            </div>
            <div class="form-group">
                <small class="text-muted">New Appointment Date <b class="ml-2 text-danger" *ngIf="!date">*</b></small>
                <input type="date" class="form-control" [(ngModel)]="date" (change)="listSchedules()"
                    [disabled]="!service">
            </div>
            <div class="form-group" *ngIf="service && !service?.is_auto_booking && schedule?.slots?.length > 0">
                <small class="text-muted">Slot <b class="ml-2 text-danger" *ngIf="!slot">*</b></small>
                <select class="form-control" [(ngModel)]="slot" *ngIf="!searching" [disabled]="searching">
                    <option hidden selected value="null">Select Slot</option>
                    <option [ngValue]="slot" *ngFor="let slot of schedule?.slots">
                        {{ slot?.slot_start_time_string }} - {{ slot?.slot_end_time_string }}
                    </option>
                </select>
                <div class="text-center" *ngIf="searching">
                    Updating <i class="ml-2 nav-icon fas fa-circle-notch spinning"></i>
                </div>
            </div>
            <div *ngIf="service && !service?.is_auto_booking && schedule?.slots?.length < 1">
                <span *ngIf="!searching">No slots for the selected date</span>
                <div class="text-center" *ngIf="searching">
                    Updating <i class="ml-2 nav-icon fas fa-circle-notch spinning "></i>
                </div>
            </div>
        </div>

        <div *ngIf="loadingForm" class="loading-form">
            <i class=" ml-2 nav-icon fas fa-circle-notch spinning text-base"></i>
        </div>
    </fieldset>
    <p-footer>
        <div class="w-100 text-right pr-3 pl-3 pb-3">
            <button role="button" class="btn btn-primary btn-lg mr-2" (click)="rescheduleAppointment()"
                [disabled]="processing || !complaint || (!slot && !service?.is_auto_booking)">
                <!-- <button role="button" class="btn btn-primary btn-lg mr-2" (click)="createAppointment()"> -->
                Reschedule
                <i *ngIf="processing" class="ml-2 nav-icon fas fa-circle-notch spinning"></i>
            </button>
            <button role="button" class="btn btn-outline-primary btn-lg" (click)="onClose()" [disabled]="processing">
                Close
            </button>
        </div>
    </p-footer>
</p-dialog>
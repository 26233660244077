<p-dialog
  [baseZIndex]="2000"
  [(visible)]="modal"
  [style]="{ minWidth: '600px', maxWidth: '600px' }"
  [modal]="true"
  position="center"
  [draggable]="false"
  [dismissableMask]="true"
  closeIcon=""
  styleClass="select-template-dialog"
  (onHide)="onHide()"
>
  <div class="pr-3 pl-3">
    <h3 class="text-primary bold">Select New Form</h3>
    <form [formGroup]="filterForm" *ngIf="filterForm" class="mt-3">
      <fieldset [disabled]="processing">
        <div class="form-group">
          <div class="input-icon">
            <input
              type="text"
              formControlName="query"
              class="form-control"
              placeholder="Enter Form Name"
              (keyup.enter)="listTemplates()"
            />
            <i class="fas fa-search"></i>
          </div>
        </div>
        <div class="form-group" *ngIf="showAssociatedVisit">
          <i class="fas fa-info-circle cursor-pointer pr-1" data-bs-toggle="tooltip" data-bs-placement="top"
            title="You must create an outpatient or inpatient visit before creating a new note"></i>
          <span class="text-muted">Associated Visit</span>
          <select class="form-control" formControlName="visit_id">
            <option hidden value="null">Select Visit Date</option>
            <option [value]="visit?.visit_id" *ngFor="let visit of visits?.data">
                {{ visit?.admission_date_string }}
            </option>
          </select>
        </div>
      </fieldset>
    </form>
    <div class="bordered-gray template-container">
      <ul class="template-list" *ngIf="!processing">
        <li *ngFor="let template of templates?.data">
          <a (click)="selectedTemplate = template" [class.bg-success]="selectedTemplate === template">
            <i class="far fa-file-alt"></i>
            <p class="mb-0">{{ template?.name }}</p>
          </a>
        </li>
      </ul>
      <ul class="template-list" *ngIf="processing">
        <li *ngFor="let count of counter(8)" class="mb-2">
          <p-skeleton borderRadius="10px" height="3.5rem"></p-skeleton>
        </li>
      </ul>
    </div>
    <span class="text-muted">
      <div class="row mt-1">
        <div class="col-12">
          <span class="text-muted">
            <strong>Tip: </strong>
            You can use the Comprehensive SOAP Notes to create your SOAP Note, <br>
            E-Prescription, Diagnostic Order Requests, and Patient Instructions all in one go!
          </span>
        </div>
      </div>
    </span>
  </div>
  <p-footer>
    <div class="w-100 pr-3 pl-3 pb-3">
      <button
      class="btn btn-primary btn-lg w-100"
      (click)="onSelect()"
      *ngIf="!processing"
      [disabled]="!selectedTemplate || !filterForm?.value?.visit_id"
    >
      Select Form
    </button>
    </div>
  </p-footer>
</p-dialog>

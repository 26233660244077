<form [formGroup]="form" *ngIf="form">
    <fieldset [disabled]="processing || loading">
        <div class="row text-left">
            <div class="col-6 bold h6">
                <span class="bold-400 text-muted" for="specialty">Specialty</span>
                <b class="text-red ml-2" *ngIf="getControl('specialties')?.errors?.required">*</b>
                <app-autocomplete #specialtyComp (search)="searchSpecialties($event)" [results]="specialties" [field]="'text'" (select)="updateSpecialtyForm()" (unselect)="updateSpecialtyForm()"></app-autocomplete>
            </div>
            <div class="col-6 bold h6">
                <span class="bold-400 text-muted" for="sub-specialty">Sub - Specialty</span>
                <app-autocomplete #subspecialtyComp (search)="searchSubspecialties($event)" [results]="subspecialties" [field]="'text'" (select)="updateSubspecialtyForm()" (unselect)="updateSubspecialtyForm()"></app-autocomplete>
            </div>
            <div class="col-6 bold h6 border-style">
                <div class="row">
                    <div class="col-12 col-md-12 py-2">
                        <span class="bold-500 text-muted">Supporting Documents for Specialties (Not required if 'General Practitioner')</span>
                    </div>
                    <div class="col-12 col-md-12 py-2">
                        <div class="prc-container">
                            <app-file-upload #fileUploader (preview)="setImageControl($event,'prcID','license.file')"
                                label="PRC ID Image" [image]="''" [showUploadButton]="false"></app-file-upload>
                            <button type="button" class="btn btn-dark bg-lightgray bold mt-2" style="max-width: 50%; align-self: center;"
                                (click)="fileUploader.open()"><i class="fas fa-file-upload pr-2"
                                    aria-hidden="true"></i>Browse</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6 bold h6">
                <span class="bold-400 text-muted my-2">Uploaded Files</span><br/>
                <div class="row px-2">
                    <div *ngFor="let file of doctor?.prc_license?.multiple_files"
                        class="col p-2 text-center d-flex flex-column" style="max-width: 200px; width: fit-content;">
                        <button type="submit" class="close" (click)="removeFile(file)">
                            <span>&times;</span>
                        </button>
                        <img [src]="file?.file_url" alt="" class="img-preview" />
                        <span>{{ file?.name }}</span>
                    </div>
                    <div class="col p-2" *ngIf="doctor?.prc_license?.multiple_files?.length < 1">
                        <span>No uploaded files yet!</span>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <hr>
            </div>
            <div class="col-6 bold h6 pb-0">
                <span class="bold-400 text-muted">Hospital/Clinic Affiliations</span>
                <!-- <small for="hospital-affiliations" class="text-gray text-sm">Enter the hospitals and clinics that you are affiliated with.</small> -->
                <div class="form-group">
                    <app-autocomplete #hospitalComp (search)="searchHospitalMasters($event)" [results]="hospitalMasters" [field]="'facility_name'" (select)="updateHospitalForm()" (unselect)="updateHospitalForm()"></app-autocomplete>
                </div>
            </div>
            <div class="col-6 bold h6 pb-0">
                <span class="bold-400 text-muted">HMO</span>
                <!-- <small for="hospital-accreditations" class="text-gray text-sm">Enter the HMOs that you accept.</small> -->
                <app-autocomplete #hmoComp (search)="searchHMOMasters($event)" [results]="hmoMasters" [field]="'hmo_text'" (select)="updateHmoForm()" (unselect)="updateHmoForm()"></app-autocomplete>
            </div>
            <!-- <div class="col-12 bold h6 pb-0">
              <span class="bold-400 text-muted">Medical Associations</span>
            </div>
            <div class="col-12 pb-4">
              <small for="hospital-accreditations" class="text-gray text-sm">Enter the medical associations you are affiliated with.</small>
              <app-autocomplete #society (search)="searchSociety($event)" [results]="societyLists" [field]="'society_description'" (select)="updateSocietyForm()"
                                (unselect)="updateSocietyForm()"></app-autocomplete>
            </div> -->
            
        </div>
    </fieldset>
</form>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ScheduleService } from 'src/app/utils/services/schedule/schedule.service';
import { DoctorService } from 'src/app/utils/services/doctor/doctor.service';
import { VIRTAUL_CLINIC } from 'src/app/utils/items/clinic-type';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AppService } from 'src/app/utils/services/app.service';
import * as moment from 'moment';

@Component({
  selector: 'app-reschedule-appointment-modal',
  templateUrl: './reschedule-appointment-modal.component.html',
  styleUrls: ['./reschedule-appointment-modal.component.scss']
})
export class RescheduleAppointmentModalComponent {
  @Output() reschedule: EventEmitter<any> = new EventEmitter<any>();

  clinics: Array<any>;
  user: any;
  modal = false;
  date: any;
  schedule: any;
  complaint: string;
  service: any;
  service_item_code: any;
  appointment: any;
  slot: any;
  clinic: any;
  searching = false;
  processing = false;
  loadingForm: boolean = true;

  constructor(
    private _serviceClient: ScheduleService,
    private _appService: AppService,
    private doctorService: DoctorService,
    private toastr: ToastrService
  ) { this.user = this._appService.getCurrentUser(); }

  init(): void {
    this.modal = true;
    this.loadingForm = true;
    this.processing = false;
    this.clinics = null;
    this.slot = null;
  }

  onOpen(appointment: any) {
    this.init();

    of(appointment)
      .pipe(
        filter(appt => !!appt),
        switchMap(appt => {
          this.appointment = appt;
          this.complaint = appt.visit_reason;
          this.date = moment().format('YYYY-MM-DD');
          return this.getClinics().pipe(map(res => res.data));
        }),
        switchMap(clinics => {
          this.clinics = clinics;
          this.clinic = this.clinics?.find(c => c.resource.resource_id == this.appointment.resource_id);
          this.service = this.clinic.resource.services.find(s => s.service_item_code === this.appointment.service.service_item_code);
          return this.getServiceSchedule();
        }))
      .subscribe(schedule => {
        this.schedule = schedule;
        this.loadingForm = false;

        if (schedule.slots?.length > 0) {
          this.slot = schedule.slots[0];
        }
      }, err => {
        this.loadingForm = false;
      });
  }

  clinicChange() {
    this.service = this.clinic.resource.services.find(s => s.service_item_code === this.appointment.service.service_item_code);
    this.listSchedules();
  }

  getClinics(): Observable<any> {
    return this.doctorService.getClinics({
      md_id: this.user?.doctor?.md_id,
      includes: 'resource.services.service_item',
      except_clinic_type: [VIRTAUL_CLINIC?.code]
    });
  }

  getServiceSchedule(): Observable<any> {
    return this._serviceClient.getServiceSchedules(this.service?.service_item_code, {
      date: this.date,
      resource_id: this.clinic?.resource?.id,
      clinic_id: this.clinic?.id
    });
  }

  listSchedules() {
    this.searching = true;
    this.slot = null;

    /* if (moment(this.date).isBefore(moment(), 'day')) {
      alert('Please select future date')
    } */

    this.getServiceSchedule()
      .subscribe((response: any) => {
        this.searching = false;
        this.slot = response?.slots?.[0];
        this.schedule = response;
        this.searching = false;
      }, (err) => {
        this.searching = false;
      });
  }

  rescheduleAppointment() {
    this.processing = true;

    this._serviceClient.book(this.appointment?.id, {
      patient_id: this.appointment?.patient?.patient_id,
      service_id: this.service.id,
      resource_id: this.service.resource_id,
      slot_id: this.slot?.id,
      complaint: this.complaint,
      date: this.date,
      appointment_id: this.appointment?.id,
      visit_id: this.appointment?.visit_id,
    })
      .subscribe((response: any) => {
        console.log('rescheduleAppointment success:', response);
        this.processing = false;

        if (response?.success === true) {
          this.reschedule.emit();
          this.toastr.success("Successfully rescheduled an appointment.");
          this.modal = false;
        }
      }, (err) => {
        this.processing = false;
        console.log('rescheduleAppointment error:', err);
        this.toastr.error("An error occured when rescheduling an appointment.");
      });
  }

  onClose() {
    this.modal = false;
  }
}

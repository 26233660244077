import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { mpi_url, appendQueryParams } from '../../helper/url';

@Injectable({
  providedIn: 'root',
})
export class AnthropometricService {

  constructor(private http: HttpClient) {}
  
  get(id, queryParams = {}) {
    return this.http.get(
      `${mpi_url}/basic-anthropometrics/${id}${appendQueryParams(queryParams)}`
    );
  }

  list(queryParams = {}) {
    return this.http.get(
      `${mpi_url}/basic-anthropometrics${appendQueryParams(queryParams)}`
    );
  }
  
  save(data, queryParams = {}) {
    return this.http.post(
      `${mpi_url}/basic-anthropometrics${appendQueryParams(queryParams)}`, data
    );
  }
  
  update(id, data = {}, queryParams = {}) {
    return this.http.put(
      `${mpi_url}/basic-anthropometrics/${id}${appendQueryParams(queryParams)}`, data
    );
  }
  
  delete(id, queryParams = {}) {
    return this.http.delete(
      `${mpi_url}/basic-anthropometrics/${id}${appendQueryParams(queryParams)}`
    );
  }
}

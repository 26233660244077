export const series = [
  {
    value: 'Primary 1',
    display: 'Primary 1',
    shortDisplay: 'Primary 1',
  },
  {
    value: 'Primary 2',
    display: 'Primary 2',
    shortDisplay: 'Primary 2',
  },
  {
    value: 'Primary 3',
    display: 'Primary 3',
    shortDisplay: 'Primary 3',
  },
  {
    value: 'Primary 4',
    display: 'Primary 4',
    shortDisplay: 'Primary 4',
  },
  {
    value: 'Primary 5',
    display: 'Primary 5',
    shortDisplay: 'Primary 5',
  },
  {
    value: 'Primary 6',
    display: 'Primary 6',
    shortDisplay: 'Primary 6',
  },
  {
    value: 'Booster 1',
    display: 'Booster 1',
    shortDisplay: 'Booster 1',
  },
  {
    value: 'Booster 2',
    display: 'Booster 2',
    shortDisplay: 'Booster 2',
  },
  {
    value: 'Booster 3',
    display: 'Booster 3',
    shortDisplay: 'Booster 3',
  },
  {
    value: 'Booster 4',
    display: 'Booster 4',
    shortDisplay: 'Booster 4',
  },
  {
    value: 'Booster 5',
    display: 'Booster 5',
    shortDisplay: 'Booster 5',
  },
  {
    value: 'Booster 6',
    display: 'Booster 6',
    shortDisplay: 'Booster 6',
  },
];

import { Component, OnInit, Input, ElementRef, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LeaveService } from 'src/app/utils/services/doctor/leave.service';
import { takeUntil } from 'rxjs/operators';
import { Subject  } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { isAcceptableImage } from 'src/app/utils/helper/utilities';
import * as _moment from 'moment';

@Component({
  selector: 'app-request-leave-modal',
  templateUrl: './request-leave-modal.component.html',
  styleUrls: ['./request-leave-modal.component.scss']
})
export class RequestLeaveModalComponent implements OnInit {
  @ViewChild('fileUpload') fileUploadInput: ElementRef;
  @ViewChild('notificationStatusModalButton') notificationStatusModalButton: ElementRef;

  modal = false;
  processing = false;
  form: FormGroup;
  attendances: any;
  page = 1;
  perPage = 5;
  perPages = [5, 10, 25, 50, 100];
  fileUpload: any;
  fileSrc: any;
  fileName: any = null;
  fileSize: any = null;
  isUpload: boolean = false;

  @Input()
  doctorId: any;
  
  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private leaveService: LeaveService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      md_id: this.doctorId,
      leave_type: [""],
      start_date: [""],
      end_date: [""],
      reason: [""],
      status: "Pending"
    });
  }

  formatAsDateOnly(timestamp = null){
    return _moment(timestamp).format('MMMM d YYYY');
  }

  formatAsTimeOnly(timestamp = null){
    return _moment(timestamp).format('h:mm:ss a');
  }

  saveSuccess(){
    this.onClose();
    this.resetForm();
    this.notificationStatusModalButton.nativeElement.click();
  }

  saveNewLeaveRequest(){
    let formData = JSON.stringify(this.form.value);
    this.processing = true;
    this.leaveService.saveNewLeaveRequest(formData).subscribe((response: any) => {
      this.processing = false;
      console.log(response);
      if (this.isUpload){
        this.leaveService.uploadLeaveRequestFile(this.fileUpload).subscribe((response: any) => {
          console.log(response);
          this.saveSuccess()
        }, (err) => {
          this.processing = false;
          console.log(err);
          this.saveSuccess()
        })
      }
      else{
        this.saveSuccess();
      }
    }, (err) => {
      this.processing = false;
      console.log(err)
    })
  }

  selectFile(event: any) {
    let fileType = event.target.files[0]?.type;
    if (!isAcceptableImage(fileType)) {
      this.toastr.error('File should be of type image, pdf or word document', '', { timeOut: 5000 });
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    this.fileName = event.target.files[0]?.name;
    this.fileSize = (event.target.files[0]?.size/1024).toFixed(1);
    reader.onload = (_event) => {
      this.fileSrc = reader.result as string;
      this.fileUpload = new FormData();
      this.fileUpload.append("file", event.target.files[0]);
      this.fileUpload.append("mimes", 'mimes:' + fileType);
      this.isUpload = true;
    }
  }

  selectFileByDragAndDrop(files: any) {
    let fileType = files[0]?.type;
    if (!isAcceptableImage(fileType)) {
      this.toastr.error('File should be of type image, pdf or word document', '', { timeOut: 5000 });
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    this.fileName = files[0]?.name;
    this.fileSize = (files[0]?.size/1024).toFixed(1);
    reader.onload = (_event) => {
      this.fileSrc = reader.result as string;
      this.fileUpload = new FormData();
      this.fileUpload.append("file", files[0]);
      this.fileUpload.append("mimes", 'mimes:' + fileType);
      this.isUpload = true;
    }
  }

  removeUploadedFile(){
    this.fileUpload = null;
    this.fileSrc = null;
    this.fileName = null;
    this.fileSize = null;
    this.isUpload = false;
    this.fileUploadInput.nativeElement.value = null;
  }

  resetForm(){
    this.form.patchValue({
      md_id: this.doctorId,
      leave_type: [""],
      from: [""],
      to: [""],
      reason: [""],
    });
  }
  

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      console.log(event);
      this.selectFileByDragAndDrop(event.dataTransfer.files);
    }
  }

  onOpen() {
    this.modal = true;
  }

  onClose() {
    this.modal = false;
  }

}

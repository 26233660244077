import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AllergyService } from 'src/app/utils/services/mpi/allergy.service';
import { PatientService } from 'src/app/utils/services/mpi/patient.service';
import { VideoCallComponent } from '../../video-call/video-call.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-patient-banner',
  templateUrl: './patient-banner.component.html',
  styleUrls: ['./patient-banner.component.scss']
})
export class PatientBannerComponent implements OnInit {

  @Output()
  start: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  patient: any;

  @Input()
  inprogressAppointment: any;

  @Input()
  videoCall: VideoCallComponent;

  appointment: any;
  allergies: any;
  patientID: any;
  patientDataSource: string;
  patientData: any;

  constructor(
    private service: AllergyService,
    private patientService: PatientService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.patientDataSource = params['patientDataSource'];
    });
    
    this.patientID = this.route.snapshot.params['patient_id'];
    
    if (this.patientDataSource == 'PE'){
      this.patientService.findPEPatient(this.patientID)
        .subscribe((response: any) => {
            this.patientData = response;
            this.patientService.findPEPatientHealthRecords(this.patientID)
              .subscribe((responseRecords: any) => {
                this.allergies = this.patientData.allergies?.map((item) => item?.substance )?.join(', ');

                let latestAnthropometrics = responseRecords?.anthropometrics[responseRecords?.anthropometrics.length - 1];

                let patientBannerData = {
                  display: this.patientData?.display_name,
                  date_of_birth_string: this.patientData?.date_of_birth,
                  age_sex_complete: this.patientData?.age + 'y / ' + this.patientData?.sex_code,
                  latest_basic_anthropometrics: {
                    height: latestAnthropometrics?.height_cm,
                    weight: latestAnthropometrics?.weight_kg,
                    created_at_formatted: latestAnthropometrics?.measurement_datetime,
                    body_mass_index: latestAnthropometrics?.body_mass_index,
                    who_classification: latestAnthropometrics?.bmi_interpretation_WHO
                  }
                }
                this.patient = patientBannerData;
              });
        });
    }
    else{
      this.service.allergiesSubject.subscribe((response: any) => {
        this.allergies = response?.map((item) => item?.substance )?.join(', ');
      });
  
      this.patientService.patientSubject.subscribe((response: any) => {
        this.patient = response;
      });
    }
  }

  ngAfterViewInit(): void {
  }

  startConsultation() {
    this.start.emit();
  }

}

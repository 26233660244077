<p-dialog
  [baseZIndex]="2000"
  [style]="{ minWidth: '600px', marginTop: '30px'  }"
  [(visible)]="modal"
  [closable]="false"
  [closeOnEscape]="false"
  [dismissableMask]="false"
  [modal]="true"
  [position]="'center'"
  [draggable]="false"
>
    <div class="pr-3 pl-3">
        <h3 class="text-primary bold">Select Patient</h3>
        <div class="form-group mt-4">
            <app-patient-autocomplete (select)="patient = $event" (unselect)="patient = null" (clear)="patient = null" #patientComp></app-patient-autocomplete>
        </div>
    </div>
    <p-footer>
        <div class="w-100 text-right pr-3 pl-3 pb-3">
        <button role="button" class="btn btn-primary btn-lg mr-2" (click)="onSelect()" [disabled]="!patient">
            Select
        </button>
        <button role="button" class="btn btn-outline-primary btn-lg" (click)="onClose()">
            Close
        </button>
        </div>
    </p-footer>
</p-dialog>

import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CasService } from 'src/app/utils/services/cas.service';
import * as moment from 'moment';

@Component({
  selector: 'app-verify-code',
  templateUrl: './verify-code.component.html',
  styleUrls: ['./verify-code.component.scss']
})
export class VerifyCodeComponent implements OnInit {
  form: any;
  processing = false;
  user: any;
  emailStatus: any;
  timeExpired: any;
  otpLength = 0;
  otpValue: string;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private casService: CasService,
  ) { }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'login-container');
    this.createForm();
    this.getUserRegistration();
    this.getEmailStatus();
  }

  getEmailStatus() {
    this.casService.registerStatus(this.user.email)
      .subscribe((response: any) => {
        if (response?.is_registered) {
          this.toastr.success("Doctor already registered!", '', {
            timeOut: 3000
          });
          this.router.navigate(['/']);
        } else if (response?.is_verified) {
          this.toastr.success("Email Address already verified!", '', {
            timeOut: 3000
          });
          this.router.navigate(['/sign-up-doctors/registration-form']);
        }
        this.emailStatus = response
        const counter = window.setInterval(() => {
          const today = moment()
          const updated_at = moment(this.emailStatus?.updated_at)
          const diff = today.diff(updated_at)
          const expiration = moment.duration(diff)
          let isExpired = expiration?.minutes() > 4 ? 1 : 0
          isExpired = expiration?.hours() > 0 ? 1 : isExpired
          isExpired = expiration?.days() > 0 ? 1 : isExpired
          this.timeExpired = {
            hours: expiration?.hours(),
            minutes: expiration?.minutes(),
            seconds: expiration?.seconds(),
            isExpired
          }
          if (isExpired) {
            clearInterval(counter);
          }
        }, 1000)
      })
  }

  getUserRegistration() {
    const _user = JSON.parse(localStorage.getItem('user-registration'));
    if (!_user) {
      this.toastr.error("Email address is not found!", '', {
        timeOut: 3000
      });
      this.back()
    }
    this.user = {
      email: atob(_user.email)
    }
  }

  createForm() {
    this.form = this.fb.group({
      code1: [null, [Validators.required]],
      code2: [null, [Validators.required]],
      code3: [null, [Validators.required]],
      code4: [null, [Validators.required]],
      code5: [null, [Validators.required]],
      code6: [null, [Validators.required]],
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'login-container');
  }

  resend() {
    this.processing = true;
    this.casService.resendCode(this.user.email, {})
      .subscribe((response: any) => {
        this.processing = false;
        this.getEmailStatus();
        this.toastr.success("Successfully resent!");
      }, (err) => {
        this.processing = false;
        console.log('err ', err)
      })
  }

  back() {
    this.router.navigate(['/sign-up-doctors']);
  }

  submit() {
    this.processing = true;
    const verification_code = this.otpValue;
    this.casService.verifyCode(this.user.email, { verification_code })
      .subscribe((response: any) => {
        this.processing = false;
        this.toastr.success("Successfully verified!");
        setTimeout(() => {
          this.router.navigate(['/sign-up-doctors/registration-form']);
        }, 500);
      }, (err) => {
        this.processing = false;
        this.toastr.error(err?.error?.message, '', {
          timeOut: 3000
        });
      })
  }

  getControl(name) {
    return this.form.get(name);
  }

  onOtpChange(otp: string) {
    this.otpLength = otp.length;
    this.otpValue = otp;
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appendQueryParams, cas_url, teleconsult_url } from '../helper/url';

@Injectable({
  providedIn: 'root'
})
export class TeleconsultService {

  constructor(private http: HttpClient) {}

  authenticate(data) {
    return this.http.post(
        `${teleconsult_url}/mwell-md/authorize`, data
    );
  }

  getAppointments(mdCode, queryParams = {}) {
    return this.http.get(
        `${teleconsult_url}/mwell-md/appointments/${mdCode}${appendQueryParams(queryParams)}`
    );
  }

  getAppointmentJson() {
    return this.http.get(
        '/assets/data/appointment.json'
    );
  }
}

import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ScheduleService } from 'src/app/utils/services/schedule/schedule.service';
import { of } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AppService } from 'src/app/utils/services/app.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-zoom-appointment-modal',
  templateUrl: './zoom-appointment-modal.component.html',
  styleUrls: ['./zoom-appointment-modal.component.scss']
})
export class ZoomAppointmentModalComponent {
  @ViewChild('copyButton') copyButton: ElementRef;

  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAdd: EventEmitter<any> = new EventEmitter<any>();

  currentUser: any;
  modal = false;
  appointment: any;
  clinic: any;
  searching = false;
  processing = false;
  loadingForm: boolean = true;
  emails: Array<string> = [];
  hasError: boolean;

  constructor(
    private _serviceClient: ScheduleService,
    private _appService: AppService,
    private _toastr: ToastrService,
    private clipboard: Clipboard
  ) { this.currentUser = this._appService.getCurrentUser(); }

  init(): void {
    this.modal = true;
    this.loadingForm = true;
    this.processing = false;
    this.hasError = false;
  }

  onOpen(appointment: any) {
    console.log('appointment:', appointment);
    this.init();

    of(appointment)
      .pipe(
        filter(appt => !!appt),
        take(1))
      .subscribe((appt: any) => {
        this.loadingForm = false;
        this.appointment = appt;
        this.emails = appt.zoom_meeting?.guest_emails?.split(',') || [];
      }, err => {
        this.loadingForm = false;
      });
  }

  startMeeting() {
    window.open(this.appointment?.zoom_meeting?.start_url, "_blank");
  }

  createMeeting() {
    this.loadingForm = true;

    let data = {
      host_name: this.currentUser.doctor.primary_name.full,
      host_email: this.currentUser.email
    };

    this._serviceClient.createZoomMeeting(this.appointment.id, data)
      .subscribe((response: any) => {
        this.processing = false;
        console.log('createZoomMeeting:', response);
        this.loadingForm = false;
        this.hasError = false;

        if (response.success === true) {
          this.appointment = response.appointment;
          this.emails = this.appointment.zoom_meeting?.guest_emails?.split(',') || [];
          this.onAdd.emit(this.appointment);
          this._toastr.success(response.message, null, { timeOut: 3000 });
        } else if (response.errorCode === 10) {
          this.hasError = true;
          this._toastr.error(response?.message || "An error occured when creating a zoom meeting.", null, { timeOut: 3000 });
        } else {
          this._toastr.error(response?.message || "An error occured when creating a zoom meeting.", null, { timeOut: 3000 });
        }
      }, err => {
        this.loadingForm = false;
        this._toastr.error("An error occured when creating a zoom meeting.", null, { timeOut: 3000 });
      })
  }

  deleteMeeting() {
    this.loadingForm = true;

    this._serviceClient.deleteZoomMeeting(this.appointment.zoom_meeting.zoom_id)
      .subscribe((response: any) => {
        console.log('deleteZoomMeeting:', response);

        if (response.success === true) {
          this._toastr.success(response.message, null, { timeOut: 3000 });
          this.loadingForm = false;
          this.onDelete.emit(this.appointment.id);
          this.onClose();
        } else {
          this._toastr.error(response?.message || "An error occured when deleting the zoom meeting.", null, { timeOut: 3000 });
        }
      }, err => {
        this.loadingForm = false;
        this._toastr.error("An error occured when deleting the zoom meeting.", null, { timeOut: 3000 });
      });
  }

  emailZoomLink() {
    if (this.hasError === true) { return; }

    this.processing = true;

    this._serviceClient.emailZoomLink(this.appointment.zoom_meeting.zoom_id, this.emails)
      .subscribe((response: any) => {
        this.processing = false;
        console.log('emailZoomLink:', response);

        if (response.success === true) {
          this._toastr.success("Successfully sent the links to the email(s).", null, { timeOut: 3000 });
        } else {
          this._toastr.error("An error occured when sending the links.", null, { timeOut: 3000 });
        }
      }, err => {
        this.processing = false;
        this._toastr.error("An error occured when sending the links.", null, { timeOut: 3000 });
      });
  }

  copyLink(link: string) {
    this.clipboard.copy(link);
    this._toastr.success('Patient zoom link copied to clipboard!', null, { timeOut: 1000, positionClass: 'toast-center-center', })
  }

  validateEmails() {
    this.hasError = false;

    if (this.emails?.length > 0) {
      this.emails.forEach(email => {
        this.hasError = this.hasError || !this.validateEmail(email);
      });
    }
  }

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  onClose() {
    this.modal = false;
  }
}

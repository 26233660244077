import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { DoctorService } from 'src/app/utils/services/doctor/doctor.service';
import { HmoMastersService } from 'src/app/utils/services/doctor/hmo-masters.service';
import { HmoService } from 'src/app/utils/services/doctor/hmo.service';
import { HospitalMastersService } from 'src/app/utils/services/doctor/hospital-masters.service';
import { HospitalService } from 'src/app/utils/services/doctor/hospital.service';
import { SpecialtiesService } from 'src/app/utils/services/doctor/specialties.service';
import { SpecialtyMastersService } from 'src/app/utils/services/doctor/specialty-masters.service';
import { AutocompleteComponent } from '../../autocomplete/autocomplete.component';
import { SocietyService } from '../../../utils/services/utilities/society.service';
import { AppService } from 'src/app/utils/services/app.service';
import { debounceTime, tap } from 'rxjs/operators';
import { FilesService } from 'src/app/utils/services/doctor/files.service';
import { PHYSICIAN_TYPE_CODE, PRC_PHYSICIAN_CODE } from 'src/app/utils/items/value-set-detail';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-credentials',
  templateUrl: './credentials.component.html',
  styleUrls: ['./credentials.component.scss']
})
export class CredentialsComponent implements OnInit {

  @Output()
  save: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('specialtyComp', { static: false })
  specialtyComp: AutocompleteComponent;

  @ViewChild('subspecialtyComp', { static: false })
  subspecialtyComp: AutocompleteComponent;

  @ViewChild('hospitalComp', { static: false })
  hospitalComp: AutocompleteComponent;

  @ViewChild('hmoComp', { static: false })
  hmoComp: AutocompleteComponent;

  @ViewChild('society', { static: false })
  society: AutocompleteComponent;

  user: any;
  doctor: any;
  form: FormGroup;
  loading = false;
  processing = false;
  hospitalMasters: any = [];
  specialties: any = [];
  subspecialties: any = [];
  hmoMasters: any = [];
  societyLists: any = [];
  prcID: any;

  constructor(
    private fileService: FilesService,
    public specialtyMastersService: SpecialtyMastersService,
    public specialtiesService: SpecialtiesService,
    public hmoMastersService: HmoMastersService,
    public hmoService: HmoService,
    public hospitalMastersService: HospitalMastersService,
    public hospitalService: HospitalService,
    public doctorService: DoctorService,
    public societyService: SocietyService,
    private _appService: AppService,
    private toastr: ToastrService,
    public fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this._appService.doctor$
      .pipe(
        tap(doctor => {
          this.doctor = doctor;
          this.createForm();
        }),
        debounceTime(300)
      )
      .subscribe(doctor => {
        this.setCredentials();
      });
  }

  setCredentials() {
    this.doctor?.specialties.forEach((item) => {
      this.specialtiesArray.push(this.createSpecialties(item?.specialty));
    });
    this.specialtyComp.selectedResult = this.specialties = this.doctor?.specialties.map((item) => item?.specialty);

    this.doctor?.subspecialties.forEach((item) => {
      this.subspecialtiesArray.push(this.createSubspecialties(item?.specialty));
    });
    this.subspecialtyComp.selectedResult = this.subspecialties = this.doctor?.subspecialties.map((item) => item?.specialty);

    this.doctor?.hospital_affiliations.forEach((item) => {
      this.hospitalAffiliationsArray.push(this.createAffiliation(item?.hospital_master));
    });
    this.hospitalComp.selectedResult = this.hospitalMasters = this.doctor?.hospital_affiliations.map((item) => item?.hospital_master);

    this.doctor?.hmo.forEach((item) => {
      this.hmoAccreditationsArray.push(this.createHmo(item?.hmo_master));
      this.hmoMasters.push(item?.hmo_master);
    });
    this.hmoComp.selectedResult = this.hmoMasters = this.doctor?.hmo.map((item) => item?.hmo_master);

    this.doctor?.medical_associations.forEach((item) => {
      this.associatedSocietyArray.push(this.createSociety(item));
      this.societyLists.push(item);
    });
    // this.society.selectedResult = this.societyLists = this.doctor?.medical_associations.map((item) => item);
  }

  createForm() {
    this.form = this.fb.group({
      specialties: this.fb.array([], [Validators.required, Validators.minLength(1)]),
      subspecialties: this.fb.array([]),
      hospital_affiliations: this.fb.array([]),
      hmo_accreditations: this.fb.array([]),
      associated_society: this.fb.array([]),
      license: this.fb.group({
        id: [this.doctor?.prc_license?.id],
        id_type_code: [this.doctor?.prc_license?.id_type_code || PHYSICIAN_TYPE_CODE],
        id_type_text: [this.doctor?.prc_license?.id_type_text || 'Physician'],
        id_type_display: [this.doctor?.prc_license?.id_type_display || 'Physician'],
        type_code: [this.doctor?.prc_license?.type_code || PRC_PHYSICIAN_CODE],
        type_text: [this.doctor?.prc_license?.type_text || 'PRC Physician'],
        type_display: [this.doctor?.prc_license?.type_display || 'PRC Physician'],
        number: [this.doctor?.prc_license?.number, [Validators.required]],
        issued_code: [this.doctor?.prc_license?.issued_code],
        issued_text: [this.doctor?.prc_license?.issued_text],
        issued_display: [this.doctor?.prc_license?.issued_display],
        validity_granted_datetime: [this.doctor?.prc_license?.validity_granted_datetime],
        validity_start_datetime: [this.doctor?.prc_license?.validity_start_datetime, [Validators.required]],
        validity_renewal_datetime: [this.doctor?.prc_license?.validity_renewal_datetime],
        validity_expiry_datetime: [this.doctor?.prc_license?.validity_expiry_datetime, [Validators.required]],
        validity_is_renewable: [this.doctor?.prc_license?.validity_is_renewable || 0],
        validity_is_expired: [this.doctor?.prc_license?.validity_is_expired || 0],
        status_code: [this.doctor?.prc_license?.status_code],
        status_text: [this.doctor?.prc_license?.status_text],
        status_display: [this.doctor?.prc_license?.status_display],
        notes: [this.doctor?.prc_license?.notes],
        attachment: [this.doctor?.prc_license?.attachment],
        file: this.fb.group({
          id: [null],
          batch_number: [1],
          rename: [null],
          name: [null],
          description: [null],
          category: ['license'],
          version: [1],
        })
      })
    })
  }

  createSpecialties(specialty) {
    return this.fb.group({
      type_code: ['SP'],
      type_text: ['Specialty'],
      type_display: ['Specialty'],
      specialty_code: [specialty?.code],
      specialty_text: [specialty?.text],
      specialty_display: [specialty?.display],
      parent_specialty_code: [specialty?.parent_specialty?.code],
      parent_specialty_text: [specialty?.parent_specialty?.text],
      parent_specialty_display: [specialty?.parent_specialty?.display],
    });
  }

  createSubspecialties(specialty) {
    return this.fb.group({
      type_code: ['SSP'],
      type_text: ['SubSpecialty'],
      type_display: ['SubSpecialty'],
      specialty_code: [specialty?.code],
      specialty_text: [specialty?.text],
      specialty_display: [specialty?.display],
      parent_specialty_code: [specialty?.parent_specialty?.code],
      parent_specialty_text: [specialty?.parent_specialty?.text],
      parent_specialty_display: [specialty?.parent_specialty?.display],
    });
  }

  createHmo(item) {
    return this.fb.group({
      hmo_code: [item?.hmo_code],
      hmo_text: [item?.hmo_text],
      hmo_display: [item?.hmo_display],
    });
  }

  createSociety(item) {
    return this.fb.group({
      society_category: [item?.society_category],
      society_code: [item?.society_code],
      society_description: [item?.society_description]
    });
  }

  createAffiliation(item) {
    return this.fb.group({
      health_facility_code_short: [item?.health_facility_code_short]
    });
  }

  searchHMOMasters(term) {
    this.hmoMastersService.getHMOMasters({ query: term })
      .subscribe((response: any) => {
        this.hmoMasters = response?.data
      });
  }

  searchSociety(term) {
    this.societyService.getSocieties({ query: term })
      .subscribe((response: any) => {
        this.societyLists = response?.data;
      });
  }

  searchHospitalMasters(term) {
    this.hospitalMastersService.getHospitalMasters({ query: term, active: true })
      .subscribe((response: any) => {
        this.hospitalMasters = response?.data;
      });
  }

  searchSpecialties(term) {
    this.specialtyMastersService.getSpecialtyMasters({ query: term, specialty: true })
      .subscribe((response: any) => {
        this.specialties = response?.data;
      });
  }

  searchSubspecialties(term) {
    this.specialtyMastersService.getSpecialtyMasters({ query: term, subspecialty: true })
      .subscribe((response: any) => {
        this.subspecialties = response?.data;
      });
  }

  updateSpecialtyForm() {
    this.specialtiesArray.clear();
    this.specialtyComp.selectedResult.forEach((item) => {
      this.specialtiesArray.push(this.createSpecialties(item));
    });
  }

  updateSubspecialtyForm() {
    this.subspecialtiesArray.clear();
    this.subspecialtyComp.selectedResult.forEach((item) => {
      this.subspecialtiesArray.push(this.createSubspecialties(item));
    });
  }


  updateHospitalForm() {
    this.hospitalAffiliationsArray.clear();
    this.hospitalComp.selectedResult.forEach((item) => {
      this.hospitalAffiliationsArray.push(this.createAffiliation(item));
    });
  }

  updateHmoForm() {
    this.hmoAccreditationsArray.clear();
    this.hmoComp.selectedResult.forEach((item) => {
      this.hmoAccreditationsArray.push(this.createHmo(item));
    });
  }

  updateSocietyForm() {
    this.associatedSocietyArray.clear();
    this.society.selectedResult.forEach((item) => {
      this.associatedSocietyArray.push(this.createSociety(item));
    });
  }

  get specialtiesArray() {
    return this.form.get('specialties') as FormArray;
  }

  get subspecialtiesArray() {
    return this.form.get('subspecialties') as FormArray;
  }

  get hospitalAffiliationsArray() {
    return this.form.get('hospital_affiliations') as FormArray;
  }

  get hmoAccreditationsArray() {
    return this.form.get('hmo_accreditations') as FormArray;
  }

  get associatedSocietyArray() {
    return this.form.get('associated_society') as FormArray;
  }

  getControl(name) {
    return this.form.get(name);
  }

  setImageControl(file, name, control) {
    this[name] = file;
    this.form.get(control).patchValue({
      name: file?.file?.name,
      rename: file?.preview
    });
  }

  removeFile(file) {
    if (confirm("Are you sure you want to delete this document?")) {
      this.processing = true;
      this.fileService.deleteFile(file?.id)
        .subscribe((response: any) => {
          this.toastr.success("Successfully deleted an document");
          this.processing = false;
          const index = this.doctor?.prc_license?.multiple_files?.findIndex((item) => item.id === file.id)
          this.doctor.prc_license.multiple_files.splice(index,1);
        }, (err) => {
          this.processing = false;
        });
    }
  }
}

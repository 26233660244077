import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class VideoContainerService {
  private isMinimizedSubject = new BehaviorSubject<boolean>(false);
  isMinimized$ = this.isMinimizedSubject.asObservable();

  private isVisibleSubject = new BehaviorSubject<boolean>(false);
  isVisible$ = this.isVisibleSubject.asObservable();

  private isTeleconsultation = new BehaviorSubject<boolean>(false);
  isTeleconsultation$ = this.isTeleconsultation.asObservable();

  private callUrlSubject = new BehaviorSubject<string>('');
  callUrl$ = this.callUrlSubject.asObservable();

  private currentRouteSubject = new BehaviorSubject<string | null>(null);
  currentRoute$ = this.currentRouteSubject.asObservable();

  private position = { x: 10, y: 10 };

  constructor(private router: Router) {}

  toggleMinimize() {
    console.log('video minimize');
    this.isMinimizedSubject.next(!this.isMinimizedSubject.value);
  }

  setVisibility(isVisible: boolean) {
    this.isVisibleSubject.next(isVisible);
  }

  setTeleconsult(isTeleconsultation: boolean) {
    this.isTeleconsultation.next(isTeleconsultation);
  }

  setCallUrl(url: string) {
    this.callUrlSubject.next(url);
  }

  setPosition(x: number, y: number) {
    this.position = { x, y };
  }

  getPosition() {
    return this.position;
  }

  setCallUrlAndShow(url: string) {
    this.callUrlSubject.next(url);
    this.isVisibleSubject.next(true);
    this.isMinimizedSubject.next(false);
  }

  saveCurrentRoute() {
    const currentRoute = this.router.url.split('/').slice(2).join('/');
    this.currentRouteSubject.next(currentRoute);
  }

  getCurrentRoute(): string | null {
    return this.currentRouteSubject.value;
  }
}
export const days = [
  {
    value: '01',
    display: '01',
  },
  {
    value: '02',
    display: '02',
  },
  {
    value: '03',
    display: '03',
  },
  {
    value: '04',
    display: '04',
  },
  {
    value: '05',
    display: '05',
  },
  {
    value: '06',
    display: '06',
  },
  {
    value: '07',
    display: '07',
  },
  {
    value: '08',
    display: '08',
  },
  {
    value: '09',
    display: '09',
  },
  {
    value: '10',
    display: '10',
  },
  {
    value: '11',
    display: '11',
  },
  {
    value: '12',
    display: '12',
  },
  {
    value: '13',
    display: '13',
  },
  {
    value: '14',
    display: '14',
  },
  {
    value: '15',
    display: '15',
  },
  {
    value: '16',
    display: '16',
  },
  {
    value: '17',
    display: '17',
  },
  {
    value: '18',
    display: '18',
  },
  {
    value: '19',
    display: '19',
  },
  {
    value: '20',
    display: '20',
  },
  {
    value: '21',
    display: '21',
  },
  {
    value: '22',
    display: '22',
  },
  {
    value: '23',
    display: '23',
  },
  {
    value: '24',
    display: '24',
  },
  {
    value: '25',
    display: '25',
  },
  {
    value: '26',
    display: '26',
  },
  {
    value: '27',
    display: '27',
  },
  {
    value: '28',
    display: '28',
  },
  {
    value: '29',
    display: '29',
  },
  {
    value: '30',
    display: '30',
  },
  {
    value: '31',
    display: '31',
  },
];

import { query } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FormService } from 'src/app/utils/services/forms/forms.service';
import { MedicalHistoryService } from 'src/app/utils/services/mpi/medical-history.service';
import { UtilitiesService } from 'src/app/utils/services/utilities/utilities.service';

@Component({
  selector: 'app-clinical-icd-selection-modal',
  templateUrl: './clinical-icd-selection-modal.component.html',
  styleUrls: ['./clinical-icd-selection-modal.component.scss']
})
export class ClinicalIcdSelectionModalComponent implements OnInit {
  @Output()
  return: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  patientID: any;

  @Input()
  document: any;

  @Input()
  form: FormGroup;

  @Input()
  formValidator: FormGroup;

  modal = false;
  identifier: any;

  processing = true;
  codes: any;

  page = 1;
  perPage = 10;

  perPages = [5, 10, 25, 50, 100];
  activeSort = 'asc';
  activeColumn = 'created_at';
  searchText: '';

  constructor(
    private utilitiesService: UtilitiesService,
    private formService: FormService,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {    
  }
 
  list(page = 1) {
    this.processing = true;
    const queryParams: any = {};

    queryParams.perPage = this.perPage;
    queryParams.page = this.page = page;
    queryParams.search = this.searchText;

    this.utilitiesService.getIcdCode(queryParams).subscribe((response :any) => {
      this.codes = response;
      this.processing = false;
    });
  }

  onOpen(name) {
    this.modal = true;
    this.identifier = name;
    this.list();
  }

  onClose() {
    this.searchText = '';
    this.modal = false;
  }

  selectICD(code) {  
    const arr = (this.form.get(this.identifier) as FormArray);  
    const last = arr?.at(arr?.length - 1);
    let hasValue = false;
    let diagnostic_text = null;  

    // Remove last instance if empty
    if(arr?.value?.length > 0 ) {
      
      Object.keys(last?.value).forEach((key) => {
        if(key != 'op_soap_notes_secondary_diagnosis_text') {
          if(last?.value[key]) {
            hasValue = true;
          }
        }
      });

      if(!hasValue || this.identifier == 'opd_sn_primary_diagnosis_array') {
        arr?.removeAt(arr?.length - 1);
      }
    } 
    
    if(arr?.value?.length == 0) {
      diagnostic_text = last?.value?.op_soap_notes_secondary_diagnosis_text ;
    }

    arr?.push(this.fb.group({
      op_soap_notes_icd_10_code: [code?.icd_10_code, this.formValidator['op_soap_notes_icd_10_code']],
      op_soap_notes_icd_10_description: [code?.icd_10_description, this.formValidator['op_soap_notes_icd_10_description']],
      op_soap_notes_secondary_diagnosis_text: [diagnostic_text, this.formValidator['op_soap_notes_secondary_diagnosis_text']]
    }));


    this.onClose();
    this.toastr.success(`Successfully selected ICD 10 code`);
  }

  searchCode(value) {
    this.searchText = value;
    this.list();
  }

}
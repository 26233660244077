<div>
  <div class="col text-left mt-5 cursor-pointer">
    <a href="javascript:;" class="text-primary" (click)="returnToLogin()"><span
        class="fas fa-arrow-left fa-lg text-primary fa-sm pr-2"></span>Return to login</a>
  </div>
  <div class="card light-shadow position-relative mt-3">
    <div class="grid">
      <div class="row">
        <div class="col-12 text-center">
          <div class="my-3">
            <img class="logo pr-2" src="assets/img/mwell-MD-icon.png" width="90px">
            <img class="logo" src="assets/img/logo.png" width="200px">
          </div>
        </div>
      </div>
      <div class="container pt-4">
        <div class="row">
          <div class="col-12">
            <form *ngIf="form" [formGroup]="form">
              <fieldset [disabled]="processing">
                <div class="row" formGroupName="name">
                  <div class="col-2">
                    <span for="prefix">Prefix / Title</span>
                    <b class="text-red ml-2" *ngIf="getControl('name.prefix')?.errors?.required">*</b>
                    <select id="prefix" class="form-control" formControlName="prefix">
                      <option value="null" hidden>None</option>
                      <option value="Mr.">Mr.</option>
                      <option value="Ms.">Ms.</option>
                      <option value="Mrs.">Mrs.</option>
                      <option value="Dr.">Dr.</option>
                      <option value="Atty.">Atty.</option>
                      <option value="Engr.">Engr.</option>
                    </select>
                    <small class="text-danger"
                      *ngIf="getControl('name.prefix').touched && getControl('name.prefix')?.errors?.required">Prefix /
                      Title is required.</small>
                  </div>
                  <div class="col">
                    <span for="firstName">First Name</span>
                    <b class="text-red ml-2" *ngIf="getControl('name.first')?.errors?.required">*</b>
                    <input id="firstName" formControlName="first" type="text" class="form-control" />
                    <small class="text-danger"
                      *ngIf="getControl('name.first').touched && getControl('name.first')?.errors?.required">First Name
                      is required.</small>
                    <small class="text-danger"
                      *ngIf="getControl('name.first').touched && getControl('name.first')?.errors?.minlength">First Name
                      must be at least 2 characters.</small>
                  </div>
                  <div class="col">
                    <span for="middleName">Middle Name (Optional)</span>
                    <input id="middleName" formControlName="middle" type="text" class="form-control" />
                  </div>
                  <div class="col">
                    <span for="lastName">Last Name</span>
                    <b class="text-red ml-2" *ngIf="getControl('name.last')?.errors?.required">*</b>
                    <input id="lastName" formControlName="last" type="text" class="form-control" />
                    <small class="text-danger"
                      *ngIf="getControl('name.last').touched && getControl('name.last')?.errors?.required">Last Name is
                      required.</small>
                    <small class="text-danger"
                      *ngIf="getControl('name.last').touched && getControl('name.last')?.errors?.minlength">Last Name
                      must be at least 2 characters.</small>
                  </div>
                  <div class="col-2">
                    <span for="suffix">Suffix (Optional)</span>
                    <select id="suffix" class="form-control" formControlName="suffix">
                      <option value="null" hidden>None</option>
                      <option value="">None</option>
                      <option value="Jr.">Jr.</option>
                      <option value="Sr.">Sr.</option>
                      <option value="III">III</option>
                      <option value="IV">IV</option>
                      <option value="V">V</option>
                      <option value="VI">VI</option>
                      <option value="VII">VII</option>
                      <option value="VIII">VIII</option>
                      <option value="IX">IX</option>
                      <option value="X">X</option>
                    </select>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-8">
                    <div>Present Address</div>
                    <div class="row prc-container p-3" formGroupName="address">
                      <div class="col-9 pb-3">
                        <span for="line1">Street / Building / House Number</span>
                        <b class="text-red ml-2" *ngIf="getControl('address.line1')?.errors?.required">*</b>
                        <input id="line1" formControlName="line1" type="text" class="form-control" />
                        <small class="text-danger"
                          *ngIf="getControl('address.line1').touched && getControl('address.line1')?.errors?.required">Street
                          / Building / House Number is required.</small>
                      </div>
                      <div class="col-3">
                        <span for="zip_code">Zip Code</span>
                        <b class="text-red ml-2" *ngIf="getControl('address.zip_code')?.errors?.required">*</b>
                        <input id="zip_code" formControlName="zip_code" type="text" class="form-control"
                          appDigitOnlyDirective />
                        <small class="text-danger"
                          *ngIf="getControl('address.zip_code').touched && getControl('address.zip_code')?.errors?.required">Zip
                          Code is required.</small>
                      </div>
                      <div class="col">
                        <span for="province_code">Province / State</span>
                        <b class="text-red ml-2" *ngIf="getControl('address.province_code')?.errors?.required">*</b>
                        <select id="province_code" class="form-control" formControlName="province_code"
                          (change)="onFormChange('address.province_code')">
                          <option value="null" hidden>Select</option>
                          <option value="137600000">Metro Manila</option>
                          <option *ngFor="let province of provinces?.data" [value]="province?.code">
                            {{ province?.display }}
                          </option>
                        </select>
                        <small class="text-danger"
                          *ngIf="getControl('address.province_code').touched && getControl('address.province_code')?.errors?.required">Province
                          / State is required.</small>
                      </div>
                      <div class="col">
                        <span for="city_code">Municipality / City</span>
                        <b class="text-red ml-2" *ngIf="getControl('address.city_code')?.errors?.required">*</b>
                        <select id="city_code" class="form-control" formControlName="city_code"
                          (change)="onFormChange('address.city_code')">
                          <option value="null" hidden>Select</option>
                          <option *ngFor="let muni of filterMunicipality('address')" [value]="muni?.code">
                            {{ muni?.display }}
                          </option>
                        </select>
                        <small class="text-danger"
                          *ngIf="getControl('address.city_code').touched && getControl('address.city_code')?.errors?.required">Municipality
                          / City is required.</small>
                      </div>
                      <div class="col-3">
                        <span for="barangay_code">Barangay</span>
                        <b class="text-red ml-2" *ngIf="getControl('address.barangay_code')?.errors?.required">*</b>
                        <select id="barangay_code" class="form-control hidden" formControlName="barangay_code"
                          (change)="onFormChange('address.barangay_code')"></select>

                        <dropdown-search [options]="filterBarangay('address')"
                          [value]="getControl('address.barangay_code')?.value" optionLabel="display" filterBy="display"
                          optionValue="code" [showClear]="false" placeholder="Select"
                          (onChange)="getControl('address.barangay_code')?.setValue($event.value); getControl('address.barangay_text')?.setValue($event.display)">
                        </dropdown-search>
                        <small class="text-danger"
                          *ngIf="getControl('address.barangay_code').touched && getControl('address.barangay_code')?.errors?.required">Barangay
                          is required.</small>
                      </div>
                    </div>
                  </div>
                  <div class="col pl-4">
                    <div>Contact Details</div>
                    <div class="row prc-container p-3" formGroupName="contact_details">
                      <div class="col-12">
                        <span for="email">Email Address</span>
                        <b class="text-red ml-2" *ngIf="getControl('contact_details.email')?.errors?.required">*</b>
                        <input id="email" formControlName="email" type="text" class="form-control" />
                        <small class="text-danger"
                          *ngIf="getControl('contact_details.email').touched && getControl('contact_details.email')?.errors?.required">Email
                          Address is required.</small>
                        <small class="text-danger"
                          *ngIf="getControl('contact_details.email').touched && getControl('contact_details.email')?.errors?.email">Please
                          provide a valid email address.</small>
                      </div>
                      <div class="col-12 mt-3">
                        <span for="mobile">Mobile Number</span>
                        <b class="text-red ml-2" *ngIf="getControl('contact_details.mobile')?.errors?.required">*</b>
                        <input id="mobile" formControlName="mobile" type="text" class="form-control" maxlength="10"
                          appDigitOnlyDirective (keydown)="preventZero($event)" />
                        <small class="text-danger"
                          *ngIf="getControl('contact_details.mobile').touched && getControl('contact_details.mobile')?.errors?.required">Mobile
                          Number is required.</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-2">
                  <div class="col-12">
                    <span>Permanent Address</span>
                  </div>
                  <div class="col-12" [class.border-style]="!isSamePresent">
                    <div class="row p-2">
                      <div class="col-12">
                        <div class="form-group">
                          <div class="custom-control custom-checkbox">
                            <input class="custom-control-input cursor-pointer" type="checkbox"
                              formControlName="is_same_present" [checked]="isSamePresent" id="profile-same-present"
                              (click)="toggleSamePresent()">
                            <label for="profile-same-present"
                              class="custom-control-label cursor-pointer text-muted">Same as Present Address</label>
                          </div>
                        </div>
                      </div>
                      <div class="col-12" [class.hidden]="isSamePresent">
                        <div class="row" formGroupName="permanent_address">
                          <div class="col-9 pb-3">
                            <span for="line1">Street / Building / House Number</span>
                            <b class="text-red ml-2"
                              *ngIf="getControl('permanent_address.line1')?.errors?.required">*</b>
                            <input id="line1" formControlName="line1" type="text" class="form-control" />
                            <small class="text-danger"
                              *ngIf="getControl('permanent_address.line1').touched && getControl('permanent_address.line1')?.errors?.required">Street
                              / Building / House Number is required.</small>
                          </div>
                          <div class="col-3">
                            <span for="zip_code">Zip Code</span>
                            <b class="text-red ml-2"
                              *ngIf="getControl('permanent_address.zip_code')?.errors?.required">*</b>
                            <input id="zip_code" formControlName="zip_code" type="text" class="form-control"
                              appDigitOnlyDirective />
                            <small class="text-danger"
                              *ngIf="getControl('permanent_address.zip_code').touched && getControl('permanent_address.zip_code')?.errors?.required">Zip
                              Code is required.</small>
                          </div>
                          <div class="col">
                            <span for="province_code">Province / State</span>
                            <b class="text-red ml-2"
                              *ngIf="getControl('permanent_address.province_code')?.errors?.required">*</b>
                            <select id="province_code" class="form-control" formControlName="province_code"
                              (change)="onFormChange('permanent_address.province_code')">
                              <option value="null" hidden>Select</option>
                              <option value="137600000">Metro Manila</option>
                              <option *ngFor="let province of provinces?.data" [value]="province?.code">
                                {{ province?.display }}
                              </option>
                            </select>
                            <small class="text-danger"
                              *ngIf="getControl('permanent_address.province_code').touched && getControl('permanent_address.province_code')?.errors?.required">Province
                              / State is required.</small>
                          </div>
                          <div class="col">
                            <span for="city_code">Municipality / City</span>
                            <b class="text-red ml-2"
                              *ngIf="getControl('permanent_address.city_code')?.errors?.required">*</b>
                            <select id="city_code" class="form-control" formControlName="city_code"
                              (change)="onFormChange('permanent_address.city_code')">
                              <option value="null" hidden>Select</option>
                              <option *ngFor="let muni of filterMunicipality('permanent_address')" [value]="muni?.code">
                                {{ muni?.display }}
                              </option>
                            </select>
                            <small class="text-danger"
                              *ngIf="getControl('permanent_address.city_code').touched && getControl('permanent_address.city_code')?.errors?.required">Municipality
                              / City is required.</small>
                          </div>
                          <div class="col-3">
                            <span for="barangay_code">Barangay</span>
                            <b class="text-red ml-2"
                              *ngIf="getControl('permanent_address.barangay_code')?.errors?.required">*</b>
                            <select id="barangay_code" class="form-control hidden" formControlName="barangay_code"
                              (change)="onFormChange('permanent_address.barangay_code')">
                              <option value="null" hidden>Select</option>
                              <!-- <option *ngFor="let barangay of filterBarangay('permanent_address')"
                                [value]="barangay?.code">
                                {{ barangay?.display }}
                              </option> -->
                            </select>
                            <dropdown-search [options]="filterBarangay('permanent_address')"
                              [value]="getControl('permanent_address.barangay_code')?.value" optionLabel="display"
                              filterBy="display" optionValue="code" [showClear]="false" placeholder="Select"
                              (onChange)="getControl('permanent_address.barangay_code')?.setValue($event.value); getControl('permanent_address.barangay_text')?.setValue($event.display)">
                            </dropdown-search>
                            <small class="text-danger"
                              *ngIf="getControl('permanent_address.barangay_code').touched && getControl('permanent_address.barangay_code')?.errors?.required">Barangay
                              is required.</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-2">
                  <div class="col" formGroupName="doctor">
                    <span for="date_of_birth" class="mb-0 bold-400 text-muted">Date of Birth</span>
                    <b class="text-red ml-2" *ngIf="getControl('doctor.date_of_birth')?.errors?.required">*</b>
                    <input id="date_of_birth" formControlName="date_of_birth" type="date" class="form-control" />
                    <small class="text-danger"
                      *ngIf="getControl('doctor.date_of_birth').touched && getControl('doctor.date_of_birth')?.errors?.required">Date
                      of Birth is required.</small>
                  </div>
                  <div class="col" formGroupName="birth_details">
                    <span for="place_birth_country" class="mb-0 bold-400 text-muted">Place of Birth
                      Country</span>
                    <b class="text-red ml-2"
                      *ngIf="getControl('birth_details.place_birth_country')?.errors?.required">*</b>
                    <select id="place_birth_country" class="form-control" formControlName="place_birth_country">
                      <option value="null" hidden>None</option>
                      <option value="PHL" selected>Philippines</option>
                      <option *ngFor="let country of countries?.data" [value]="country?.code">
                        {{ country?.name }}
                      </option>
                    </select>
                    <small class="text-danger"
                      *ngIf="getControl('birth_details.place_birth_country').touched && getControl('birth_details.place_birth_country')?.errors?.required">Place
                      of Birth Country is required.</small>
                  </div>
                  <div class="col" formGroupName="birth_details">
                    <span for="place_birth_city" class="mb-0 bold-400 text-muted">Place of Birth City</span>
                    <b class="text-red ml-2"
                      *ngIf="getControl('birth_details.place_birth_city')?.errors?.required">*</b>
                    <select id="place_birth_city" class="form-control" formControlName="place_birth_city">
                      <option value="null" hidden>None</option>
                      <option *ngFor="let muni of municipalities?.data" [value]="muni?.code">
                        {{ muni?.display }}
                      </option>
                    </select>
                    <small class="text-danger"
                      *ngIf="getControl('birth_details.place_birth_city').touched && getControl('birth_details.place_birth_city')?.errors?.required">Place
                      of Birth City is required.</small>
                  </div>
                  <div class="col" formGroupName="birth_details">
                    <span for="nationality" class="mb-0 bold-400 text-muted">Nationality</span>
                    <b class="text-red ml-2" *ngIf="getControl('birth_details.nationality')?.errors?.required">*</b>
                    <select id="nationality" class="form-control" formControlName="nationality">
                      <option value="null" hidden>None</option>
                      <option value="PHL">Filipino</option>
                      <option [value]="master.code" *ngFor="let master of nationalities?.details">
                        {{ master.text }}
                      </option>
                    </select>
                    <small class="text-danger"
                      *ngIf="getControl('birth_details.nationality').touched && getControl('birth_details.nationality')?.errors?.required">Nationality
                      is required.</small>
                  </div>
                </div>
                <div class="row p-3 prc-container" formGroupName="license">
                  <div class="col-12 pb-3">
                    <span for="Photo">PRC License Photo (JPEG or PNG)</span>
                    <b class="text-red ml-2" *ngIf="getControl('license.file.name')?.errors?.required">*</b>
                    <app-file-upload label="PRC License" [image]="doctor?.prc_license?.file?.file_url"
                      (preview)="setImageControl($event,'prcLicense','license.file')">
                    </app-file-upload>
                    <small class="text-danger"
                      *ngIf="getControl('license.file').touched && !getControl('license.file.name').value">PRC License
                      Photo is required.</small>
                  </div>
                  <div class="col">
                    <span for="profession">Profession</span>
                    <input type="text" id="license" readonly class="form-control" value="Physician" />
                  </div>
                  <div class="col">
                    <span for="prc_no">PRC License Number</span>
                    <b class="text-red ml-2" *ngIf="getControl('license.number')?.errors?.required">*</b>
                    <input id="prc_no" formControlName="number" type="text" class="form-control"
                      appDigitOnlyDirective />
                    <small class="text-danger"
                      *ngIf="getControl('license.number').touched && getControl('license.number')?.errors?.required">PRC
                      License Number is required.</small>
                  </div>
                  <div class="col">
                    <span for="validity_start_datetime">Registration Date</span>
                    <b class="text-red ml-2"
                      *ngIf="getControl('license.validity_start_datetime')?.errors?.required">*</b>
                    <input id="validity_start_datetime" formControlName="validity_start_datetime" type="date"
                      class="form-control" />
                    <small class="text-danger"
                      *ngIf="getControl('license.validity_start_datetime').touched && getControl('license.validity_start_datetime')?.errors?.required">Registration
                      Date is required.</small>
                  </div>
                  <div class="col">
                    <span for="validity_expiry_datetime">Valid Until</span>
                    <b class="text-red ml-2"
                      *ngIf="getControl('license.validity_expiry_datetime')?.errors?.required">*</b>
                    <input id="validity_expiry_datetime" formControlName="validity_expiry_datetime" type="date"
                      class="form-control" />
                    <small class="text-danger"
                      *ngIf="getControl('license.validity_expiry_datetime').touched && getControl('license.validity_expiry_datetime')?.errors?.required">Valid
                      Until is required.</small>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group mt-2 mb-0">
                      <div class="custom-control custom-checkbox">
                        <input class="custom-control-input cursor-pointer" type="checkbox"
                          formControlName="terms_conditions" id="terms_conditions">
                        <label for="terms_conditions"
                          class="custom-control-label cursor-pointer text-sm text-muted mt-1">
                          By checking, I agree to mWell's <a target="_blank" rel="noopener"
                            href="https://www.mwell.com.ph/support#tc-general">Terms and Conditions</a> of Use.
                        </label>
                      </div>
                    </div>
                    <small class="text-danger pl-4"
                      *ngIf="getControl('terms_conditions').touched && getControl('terms_conditions')?.errors?.required">Terms
                      and Conditions is required.</small>
                  </div>
                </div>
              </fieldset>
              <div class="row d-flex my-4">
                <div class="col"></div>
                <div class="col-2">
                  <a href="javascript:;" class="btn btn-outline-primary btn-block" (click)="returnToLogin()">Cancel</a>
                </div>
                <div class="col-2">
                  <button type="submit" [disabled]="processing" class="btn btn-primary btn-block"
                    (click)="submit()">Submit</button>
                </div>
                <div class="col"></div>
              </div>
            </form>
          </div>
          <div class="col ml-2 border-style hidden">
            <div class="row">
              <div class="col-12 text-primary text-center px-4 pt-4">
                <h6 class="bold">Doctor Application Checklist</h6>
              </div>
              <div class="col-12 pt-3">
                <h6>The reason could be any of the following:</h6>
              </div>
              <div class="col-12">
                <div class="d-flex flex-column pb-3">
                  <label class="container-tag">Incomplete Information
                    <input type="checkbox" value="incomplete_information" disabled
                      [checked]="onChecklist('incomplete_information')">
                    <span class="checkmark-tag"></span>
                  </label>
                  <label class="container-tag">Expired Documents or Wrong Document
                    <input type="checkbox" value="expired_documents" disabled
                      [checked]="onChecklist('expired_documents')">
                    <span class="checkmark-tag"></span>
                  </label>
                  <label class="container-tag">Poor Quality Documents
                    <input type="checkbox" value="poor_quality_documents" disabled
                      [checked]="onChecklist('poor_quality_documents')">
                    <span class="checkmark-tag"></span>
                  </label>
                  <label class="container-tag">Failure to Follow Instructions
                    <input type="checkbox" value="failure_to_follow_instructions" disabled
                      [checked]="onChecklist('failure_to_follow_instructions')">
                    <span class="checkmark-tag"></span>
                  </label>
                </div>
              </div>
              <div class="col-12">
                <h6 class="pl-1">Additional Reasons:</h6>
                <textarea rows="4" cols="10" class="form-control w-full" [value]="doctor?.user?.additional_reasons"
                  disabled></textarea>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <p-dialog [baseZIndex]="2000" [style]="{ maxWidth: '600px', marginTop: '30px'  }" [(visible)]="modal"
    [closable]="false" [closeOnEscape]="false" [dismissableMask]="false" [modal]="true" [position]="'center'"
    [draggable]="false">
    <div class="pr-3 pl-3 text-center">
      <span><i class="fas fa-times-circle fa-8x text-danger"></i></span>
      <div class="declined-text">Application Declined</div>
      <div class="row mb-4">
        <div class="col-12"><span>Sorry, your application was declined.</span></div>
        <div class="col-12"><span>You may re-submit you requirements for validation.</span></div>
      </div>
      <div class="row">
        <div class="col-12">
          <strong class="text-lg">Common reasons why an application might be declined:</strong>
        </div>
      </div>
    </div>
    <div class="container w-75">
      <ul class="text-danger">
        <li>Unclear Photo of PRC ID</li>
        <li>Expired PRC ID</li>
        <li>Invalid PRC ID</li>
        <li>Name does not match ID</li>
        <li>Registration Date does not match ID</li>
        <li>Valid Until Date does not match ID</li>
        <li>Profession does not match ID</li>
        <li>PRC License number does not match ID</li>
        <li>Invalid Mobile Number</li>
        <li>Invalid E-mail</li>
        <li>Invalid Address</li>
      </ul>
    </div>
    <p-footer>
      <div class="w-100 d-flex justify-content-center">
        <a href="javascript:;" class="btn btn-primary bold btn-okay" (click)="onModalOk()">OK</a>
      </div>
    </p-footer>
  </p-dialog>
</div>
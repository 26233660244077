import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { VideoContainerService } from 'src/app/utils/services/video-container.service';

import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-video-call-container',
  templateUrl: './video-call-container.component.html',
  styleUrls: ['./video-call-container.component.scss']
})
export class VideoCallContainerComponent implements OnInit {
  position = { x: 0, y: 0 };
  highestZIndex = 99999; // Starting z-index value
  
  isTeleconsultation: boolean = false;
  private subscription: Subscription;

  constructor(
    public videoContainerService: VideoContainerService,
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router
  ) { }

  ngOnInit() {
    this.position = this.videoContainerService.getPosition();
    this.subscription = this.videoContainerService.isTeleconsultation$.subscribe(
      isTeleconsultation => {
        this.isTeleconsultation = isTeleconsultation;
      }
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  startDrag(event: MouseEvent, videoContainer: HTMLElement) {
    this.highestZIndex += 1; 
    videoContainer.style.zIndex = `${this.highestZIndex}`;

    const rect = videoContainer.getBoundingClientRect();

    const offsetX = event.clientX - rect.left;
    const offsetY = event.clientY - rect.top;

    const onMouseMove = (moveEvent: MouseEvent) => {
      videoContainer.style.left = `${moveEvent.clientX - offsetX}px`;
      videoContainer.style.top = `${moveEvent.clientY - offsetY}px`;
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }

  toggleVideoContainer(){
    const savedRoute = this.videoContainerService.getCurrentRoute();
    if(this.isTeleconsultation){
      this.videoContainerService.setVisibility(false);
    }else{
      if (savedRoute) {
        this.router.navigateByUrl('/patient/' + savedRoute);
      } else {
        console.error('No saved route found');
      }
    }
  }
}
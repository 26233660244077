import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { AppService } from '../../utils/services/app.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { Observable, of, Subject, Subscription, } from 'rxjs';
import { ACSService } from 'src/app/utils/services/azure/acs.service';
import { CasService } from 'src/app/utils/services/cas.service';
import { ADB2C_CODE, TOKEN, CURRENT_USER_ID, IDLE_TIME, IDLE_TIME_LIMIT } from 'src/app/utils/items/storage-names';
import { ActivatedRoute, Router } from '@angular/router';
import { DoctorService } from 'src/app/utils/services/doctor/doctor.service';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  authSubscription: Subscription;
  loginForm: FormGroup;
  user: any;
  processing = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  isStaff: boolean = false;
  isDev: boolean = false;
  showPassword: boolean = false;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private _route: ActivatedRoute,
    private appService: AppService,
    private service: ApiService,
    private doctorService: DoctorService,
    private casService: CasService,
    private callService: ACSService,
    private fb: FormBuilder,
    private _toastr: ToastrService
  ) { this.adb2cLogin(); }

  ngOnInit() {
    localStorage.removeItem(IDLE_TIME);
    localStorage.removeItem(IDLE_TIME_LIMIT);
    this.renderer.addClass(document.body, 'login-container');

    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
      remember_me: false
    });
  }

  async logIn() {
    this.processing = true;

    this.service.authenticate(this.loginForm.value)
      .pipe(
        switchMap((response: any) => {
          localStorage.clear();
          this.user = response?.user;
          localStorage.setItem(TOKEN, response?.access_token);
          localStorage.setItem(CURRENT_USER_ID, response?.user?.id);    
          return this.getDoctorByUser();
        }))
      .subscribe((response: any) => {
        if (this.user?.account_status !== 'active') {
          const id = btoa(this.user?.id);
          const email = btoa(this.loginForm.value.email)
          const application_checklists = this.user?.application_checklists
          localStorage.setItem('user-registration', JSON.stringify({email, id, application_checklists}))
          if (this.user.account_status === 'deactivated') {
            this._toastr.error('Account status is declined! Please contact your mWell administrator.', null, {
              timeOut: 5000,
            });
          } else {
            this._toastr.success('Account status is still pending! Please contact your mWell administrator.', null, {
              timeOut: 5000,
            });
          }
          this.router.navigate(['/sign-up-doctors/registration-form']);
        } else {
          this.user.doctor = response;
          this.appService.storeUser(this.user);
          this.appService.setUser(this.user);
          // this.accountVerification();
          this.router.navigate(['/otp']);
        }
      }, (err) => {
        this.processing = false;
        // this.appService.logout();
      })
  }

  accountVerification() {
    if (this.user.account_status == 'active' &&
      this.user.is_validated) {
      this.router.navigate(['/account-verified']);
    } else {
      if (this.user?.active_subscription?.subscription_status_code !== 'active') {
        if (['free', 'voided'].includes(this.user?.active_subscription?.payment_status_code)) {
          this.router.navigate(['/otp']);
        } else {
          this.router.navigate(['/otp']);
        }
      } else {
        this.router.navigate(['/otp']);
      }
      this.router.navigate(['/otp']);
    }
  }

  async getDoctor() {
    this.doctorService.getDoctorByUser(this.user?.id, { includes: 'hmo,hospital_affiliations.hospital_master,clinics.resource.services.service_item' })
      .subscribe((response: any) => {
        this.user.doctor = response;
        this.appService.storeUser(this.user);
        this.accountVerification();
        // this.updateCommunication();
      });
  }

  async updateCommunication() {
    const credentials = await this.callService.generateToken();

    await this.casService.updateUser(this.user?.id, {
      communication_user_id: credentials?.user?.communicationUserId,
      communication_token: credentials?.token
    }).subscribe((response: any) => {

      this.user.communication_user_id = credentials?.user?.communicationUserId;
      this.user.communication_token = credentials?.token;
      this.appService.storeUser(this.user);
      this.router.navigate(['/dashboard']);
    }, (err) => {
      this.processing = false;
    });

  }

  /* loginWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
      this.socialService.login(user);
    });
  } */

  adb2cLogin() {
    of(localStorage.getItem(ADB2C_CODE))
      .pipe(
        filter(code => !!code),
        switchMap((code: string) => {
          localStorage.removeItem(ADB2C_CODE);
          this.processing = true;
          return this.service.authenticateAdb2c({ code: code });
        }),
        filter((response: any) => {
          if (response?.user != null) {
            return true;
          }

          this.processing = false;
          return false;
        }),
        switchMap((response: any) => {
          this.user = response?.user;
          localStorage.setItem(TOKEN, response?.access_token);
          return this.getDoctorByUser();
        }),
        take(1),
      )
      .subscribe((response: any) => {
        this.processing = false;
        this.user.doctor = response;
        this.appService.storeUser(this.user);
        this.accountVerification();
      }, err => {
        this.processing = false;
        this.appService.logout();
        console.log('PostAuth error:', err);

        this._toastr.error(err.error?.message, null, {
          timeOut: 5000,
        });
      });

    this._route.queryParams
      .pipe(
        filter(params => params['token'] != null),
        map(params => params['token']),
        switchMap((token: string) => {
          this.processing = true;
          this.appService.logout(false);
          return this.service.adb2cClaimsLogin(token);
        }),
        filter((response: any) => {
          if (response?.user != null) {
            return true;
          }

          this.processing = false;
          return false;
        }),
        switchMap((response: any) => {
          this.user = response?.user;
          localStorage.setItem(TOKEN, response?.access_token);
          return this.getDoctorByUser();
        }),
        take(1))
      .subscribe((response: any) => {
        this.processing = false;
        this.user.doctor = response;
        this.appService.storeUser(this.user);
        this.accountVerification();
      }, err => {
        this.processing = false;
        this.appService.logout();
        console.log('PostAuth error:', err);

        this._toastr.error(err.error?.message, null, {
          timeOut: 5000,
        });
      });
  }

  getDoctorByUser(): Observable<Object> {
    return this.doctorService.getDoctorByUser(this.user?.id, { includes: 'medical_documents,prc_license.file,prc_license.multiple_files,s2_license.file,ptr_license.file,specialties.specialty,subspecialties.specialty,hospital_affiliations.hospital_master,hmo.hmo_master,medical_associations,present_address,permanent_address,contact_email,contact_mobile,birth_place' });
  }


  mwellLogin() {
    window.location.href = environment.adb2c_auth_url;
  }

  signUp() {
    this.router.navigate(['/sign-up-doctors']);
  }

  mwellSignUp(): void {
    window.location.href = environment.mwell_basic + '/onboard/signup';
  }

  loginWithStaff(): void {
    this.isStaff = !this.isStaff;
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'login-container');
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  triggerShowPassword(){
    this.showPassword = !this.showPassword;
  }

  getPasswordType(){
    if (this.showPassword){
      return 'text';
    }
    else{
      return 'password';
    }
  }
}

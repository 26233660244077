<nav class="navbar navbar-expand navbar-light bg-primary" [class.no-ml]="+user?.is_new_user === 1"
  [class.main-header]="+user?.is_new_user === 0" [class.navbar-white]="!darkMode" [class.navbar-dark]="darkMode">
  <ul class="navbar-nav">
    <li class="nav-item mr-3" *ngIf="+user?.is_new_user === 0">
      <a class="nav-link expand" (click)="toggleMenuSidebar.emit()" pTooltip="Expand/Collapse" tooltipPosition="right"
        tooltipZIndex="99999">
        <i *ngIf="sidebarMenuOpened" class="text-white fas fa-lock"></i>
      <i *ngIf="!sidebarMenuOpened" class="text-white fas fa-expand-alt"></i>
      </a>
    </li>
    <li class="nav-item d-flex align-items-center hidden" *ngFor="let clinic of user?.doctor?.clinics">
      <div *ngFor="let toggle of clinic?.resource?.services" class="mr-2">
        <div class="d-flex align-items-center" *ngIf="toggle.is_on_call_toggleable && +user?.is_new_user === 0">
          <span class="mr-2">On-Call Status:</span>
          <p-inputSwitch [(ngModel)]="toggle.is_on_call" styleClass="on-call" (onChange)="toggleOnCall(toggle, $event)">
          </p-inputSwitch>
          <span class="ml-2">{{ toggle?.is_on_call ? 'Online' : 'Offline'}}</span>
        </div>
      </div>
    </li>
  </ul>
  <!-- <ul class="navbar-nav ml-auto">
    <li class="nav-item text-center">
      <small>Today is</small><br>
      <b>{{ currentDate | date:'MMMM d, y' }}</b>
    </li>
  </ul> -->
  <ul class="navbar-nav ml-auto">
    <li class="nav-item d-flex align-items-center">
      <small>
        <span class="mr-1">
          <i class="fas fa-calendar mr-1 calendar-icon"></i>
          {{ currentDate | date:'EEEE, MMMM d y' }}
        </span> 
        Welcome, {{ renderName(user) }}
      </small>
      <div class="dropdown">
        <img [src]="user?.doctor?.profile_photo_url" onerror="this.src='./assets/img/user-stock.png';"
          class="profile-pic-btn ml-2 cursor-pointer" data-toggle="dropdown">
        <ul class="dropdown-menu dropdown-menu-right pt-1 pb-1" style="width: 220px;">
          <li *ngIf="user?.role_name === 'doctor'" class="font-weight-bold text-primary"
            style="background-color: #E9FBFF; padding: 8px 18px; cursor: default;"
            (click)="$event.preventDefault(); $event.stopPropagation();">You are currently on: mWellMD</li>
          <!-- <li *ngIf="user?.role_name === 'doctor'" class="font-weight-bold text-primary nav-link"
            style="padding: 8px 18px; cursor: pointer;" (click)="switchToMwellBasic()"><i
              class="nav-icon fas fa-list-alt md mr-1"></i> Switch to:
            mWell Basic</li> -->
          <li class="font-weight-bold text-primary nav-link" style="padding: 8px 18px; cursor: pointer;"
            [routerLink]="['/profile/information']"><i class="nav-icon fas fa-user-md md mr-1"></i>
            {{ user?.role_name === 'doctor' ? 'My' : "Doctor's" }} Profile
          </li>
          <li class="font-weight-bold text-primary nav-link " style="padding: 8px 18px; cursor: pointer;"
            (click)="logout()"><i class="nav-icon fas fa-sign-out-alt mr-1"></i> Logout</li>
          <!-- <li style="padding: 8px 10px;"><a class="nav-link cursor-pointer font-weight-bold text-primary"
              (click)="logout()"><i class="nav-icon fas fa-sign-out-alt"></i> Logout</a></li> -->
        </ul>
      </div>
    </li>
<!--    <app-notifications-dropdown-menu *ngIf="+user?.is_new_user === 0"></app-notifications-dropdown-menu>-->
  </ul>
</nav>

<div class="container-fluid">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header bg-danger">
                    <h3 class="text-white">Delete this clinic?</h3>
                </div>
                <div class="card-body">
                    <p class="text-lg">Clinic Name: <strong >{{ clinic?.name_display }}</strong></p>
                    <p>This will delete {{ clinic?.name_display }} from this software. This will delete the clinic name, details and all schedules related to the clinic.</p>
                    <div class="text-right mt-3">
                        <button class="btn btn-danger" (click)="deleteClinic()"><i class="fas fa-times-circle mr-2"></i>Delete Clinic</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { extendMoment } from 'moment-range';
import * as _moment from 'moment';
import { ScheduleService } from 'src/app/utils/services/schedule/schedule.service';
import { FormBuilder } from '@angular/forms';
import { getStorage } from 'src/app/utils/helper/storage';
import { CURRENT_USER } from 'src/app/utils/items/storage-names';
import { DoctorService } from 'src/app/utils/services/doctor/doctor.service';
import { VIRTAUL_CLINIC } from 'src/app/utils/items/clinic-type';
import { PatientAutocompleteComponent } from '../../patient-autocomplete/patient-autocomplete.component';
import { AppService } from 'src/app/utils/services/app.service';

@Component({
  selector: 'app-create-appointment-modal',
  templateUrl: './create-appointment-modal.component.html',
  styleUrls: ['./create-appointment-modal.component.scss']
})
export class CreateAppointmentModalComponent implements OnInit {

  @Output()
  saved: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('patientComp', { static: true })
  patientComp: PatientAutocompleteComponent;

  @Input()
  clinics: any;

  @Input()
  item: any;

  currentUser: any;
  modal = false;
  date: any;
  schedule: any;
  complaint: any;
  service_item_code: any;
  appointment: any;
  slot: any;
  clinic: any;
  patient: any;
  searching = false;
  processing = false;
  addZoomLink: boolean;

  constructor(
    private service: ScheduleService,
    private doctorService: DoctorService,
    private toastr: ToastrService,
    private _appService: AppService
  ) { }

  ngOnInit(): void {
    this.currentUser = this._appService.getCurrentUser();
  }

  onOpen(patient = null, selectedService = null) {
    const moment = extendMoment(_moment);
    this.date = moment().format('YYYY-MM-DD');
    this.patientComp.selected = this.patient = patient;
    this.complaint = null;
    this.item = selectedService;
    this.addZoomLink = false;

    if (this.clinics) {
      this.setClinics();
    } else {
      this.getClinics();
    }

    this.searching = true;
    this.processing = false;
    this.modal = true;
  }

  setClinics() {
    this.clinic = this.clinics?.[0];
    setTimeout(() => {
      this.autoSelectService();
      this.getServiceSchedule();
    }, 1);
  }

  getClinics() {
    this.processing = true;
    this.doctorService.getClinics({
      md_id: this.currentUser?.doctor?.md_id,
      includes: 'resource.services.service_item',
      except_clinic_type: [VIRTAUL_CLINIC?.code]
    })
      .subscribe((response: any) => {
        this.processing = false;
        this.clinics = response?.data;
        this.clinic = this.clinics?.[0];

        setTimeout(() => {
          this.autoSelectService();
          this.getServiceSchedule();
        }, 1);
      }, (err) => {
        this.processing = false;
      });
  }

  onClose() {
    this.modal = false;
  }

  getServiceSchedule() {
    this.searching = true;
    this.service.getServiceSchedules(this.item?.service_item_code, {
      date: this.date,
      resource_id: this.clinic?.resource?.id,
      clinic_id: this.clinic?.id
    })
      .subscribe((response: any) => {
        this.searching = false;
        this.slot = response?.slots?.[0];
        this.schedule = response;
      }, (err) => {
        this.searching = false;
      });
  }

  createAppointment() {
    this.processing = true;

    this.service.book(this.appointment?.id, {
      patient_id: this.patient?.patient_id,
      service_id: this.item?.id,
      resource_id: this.item?.resource_id,
      slot_id: this.slot?.id,
      complaint: this.complaint,
      date: this.date,
      host_name: this.currentUser.doctor.primary_name.full,
      host_email: this.currentUser.email,
      add_zoom_link: this.addZoomLink === true
    })
      .subscribe((response: any) => {
        console.log('createAppointment success:', response);
        this.processing = false;

        if (response.success === true) {
          this.modal = false;
          this.saved.emit();
          this.toastr.success(response.message);
        } else {
          this.toastr.error('Unable to create appointment at this time.');
        }
      }, (err) => {
        this.processing = false;
        this.toastr.error('Unable to create appointment at this time.');
      });
  }

  setPatient(patient) {
    this.patient = patient;
  }

  clearPatient() {
    this.patient = null;
  }

  autoSelectService() {
    if (!this.clinic?.resource?.services?.find((item) => +item?.id === +this.item?.id)) {
      this.item = this.clinic?.resource?.services?.[0];
    }
  }

  showZoom() {
    let emails = ['saberrayed@gmail.com', 'testdoc1.mwell@gmail.com', 'testdoc2.mwell@gmail.com', 'testdoc3.mwell@gmail.com', 'testdoc4.mwell@gmail.com', 'testdoc5.mwell@gmail.com', 'testdoc6.mwell@gmail.com'];
    return emails.includes(this.currentUser.email?.toLowerCase());
  }
}
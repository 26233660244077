<div class="p-5">
    <div class="p-3">
        <div class="d-flex justify-content-center w-100">
            <div class="icon d-flex justify-content-center align-items-center"><i class="fas fa-envelope"></i></div>
        </div>
        <div class="d-flex flex-column align-items-center">
            <h3 class="text-primary text-center bold mt-3">Check your email inbox</h3>
            <p class="text-center">A verification link has been sent to your email address <b>{{ form?.value?.email }}</b>. Please check your email.</p>
            <p class="text-center">Tip: Check you spam folder in case the email was incorrectly identified.</p>
            <a class="text-center text-primary cursor-pointer bold-500" (click)="didntReceive?.onOpen()">Didn't receive the email?</a>
            <a class="text-warning cursor-pointer bold-500" (click)="resendEmail?.onOpen(user)">Resend Email?</a>
        </div>
    </div>
</div>
<app-didnt-receive-email-modal #didntReceive></app-didnt-receive-email-modal>
<app-resent-email-modal #resendEmail></app-resent-email-modal>
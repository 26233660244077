<p-dialog [baseZIndex]="2000" [style]="{ maxWidth: '600px', marginTop: '30px'  }" [(visible)]="modal" [closable]="false"
    [closeOnEscape]="false" [dismissableMask]="false" [modal]="true" [position]="'center'" [draggable]="false">
    <fieldset [disabled]="processing">
        <div class="pr-3 pl-3">
            <h3 class="text-primary bold">Confirmation</h3>
            <br>
            <p class="m-0">
                Merging 2 patient profiles will result in merging patient details and visit information. 
            </p>
            <p class="m-0">
                The primary patient details will take precedence over non-primary patient details. 
            </p>
            <p class="m-0">
                Visit details and clinical documents from both patients will remain.
            </p><br>
            <p><b>Note: MERGING CANNOT BE UNDONE</b></p>
            <div class="row">
                <div class="col-1"><p-checkbox inputId="ny" [(ngModel)]="checked" #confirm></p-checkbox></div>
                <div class="col-11">
                    <label for="ny" style="padding: 0;">&nbsp; &nbsp; &nbsp; I understand the above and confirm that I wish to proceed</label>
                </div>   
            </div>
                     
        </div>
    </fieldset>
    <p-footer>
        <div class="w-100 text-right pr-3 pl-3 pb-3">
            <button role="button" class="btn btn-primary btn-lg mr-2" (click)="mergePatient();"
                [disabled]="!confirm.checked || processing">
                Proceed
                <i *ngIf="processing" class="ml-2 nav-icon fas fa-circle-notch spinning"></i>
            </button>
            <button role="button" class="btn btn-outline-primary btn-lg" (click)="onClose()" [disabled]="processing">
                Cancel
            </button>
        </div>
    </p-footer>
</p-dialog>
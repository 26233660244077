import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appendQueryParams, doctor_url, schedule_url } from '../../helper/url';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {

  constructor(private http: HttpClient) { }

  clockIn(data) {
    return this.http.post(`${doctor_url}/attendance`, data);
  }

  clockOut(data) {
    return this.http.put(`${doctor_url}/attendances/clock-out`, data);
  }

  getAttendances(queryParams = {}) {
    return this.http.get(
      `${doctor_url}/attendances${appendQueryParams(queryParams)}`
    );
  }
}
